import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const NETWORK_ID = 80001;
const RPC = {
  80001: "https://rpc.ankr.com/polygon_mumbai",
  137: "https://polygon-rpc.com",
};
export const JSON_RPC_PROVIDER = new ethers.providers.JsonRpcProvider(
  RPC[NETWORK_ID],
);
export const MULTICALL_PROVIDER = MulticallWrapper.wrap(JSON_RPC_PROVIDER);

export const WEB3_MODAL_PROJECT_ID = "85428744114343dd4239387757068d3f";

export const WEB3_MODAL_CONFIG = {
  137: {
    chainId: 137,
    name: "Polygon",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    rpcUrl: RPC[137],
  },
  80001: {
    chainId: 80001,
    name: "Mumbai",
    currency: "MATIC",
    explorerUrl: "https://mumbai.polygonscan.com/",
    rpcUrl: RPC[80001],
  },
};

export const WEB3_MODAL_METADATA = {
  name: "Arkadia Garden",
  description: "Arkadia Garden app",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

export const TW_FACTORY_ADDRESS = "0x7c1d22ef01ea7215f278af3c7a4bab5228335a91";

export const NFT_DATA_CONTRACT_ADDRESS =
  "0x3c178321f5BC73494046a46b5A065F9211b7C65E";

export const MAP_PURPLE_FREN_ID = 193;

export const NFT_IDs = {
  80001: {
    seed: 0,
    fruit: 1,
    fren: 2,
    hair: 3,
    glowyBag: 4,
  },
  137: {
    seed: 0,
    hair: 1,
    fren: 2,
    fruit: 3,
    glowyBag: 4,
  },
};

export const SEED_ID = NFT_IDs[NETWORK_ID].seed;
export const HAIR_ID = NFT_IDs[NETWORK_ID].hair;
export const FREN_ID = NFT_IDs[NETWORK_ID].fren;
export const FRUIT_ID = NFT_IDs[NETWORK_ID].fruit;
export const GLOWY_BAG_ID = NFT_IDs[NETWORK_ID].glowyBag;
