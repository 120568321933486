import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
`;
export const Card = styled.div`
  border-radius: 15px;
  border: 1px solid #000;
  background: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px;
  height: 160px;
  width: 160px;
  z-index: 99;
  position: relative;
`;
export const CardImageBox = styled.div``;
export const CardTop = styled.div`
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid #000;
  background: #554bd1;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  flex-direction: column;
`;
export const CardBottom = styled.div<{ $isloading?: boolean }>`
  border-radius: 7px;
  border: 1px solid #000;
  background: #554bd1;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin-top: -10px;
  width: 103.1px;
  height: 44.915px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  opacity: ${(props) => (props.$isloading ? 0.7 : 1)};
  cursor: ${(props) => (props.$isloading ? "not-allowed" : "pointer")};
`;
export const CardImage = styled.img`
  width: 140px;
  height: 140px;
`;
export const NFTName = styled.div`
  margin-bottom: 5px;
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 10.263px; /* 51.316% */
  letter-spacing: 0.088px;
  margin-top: 10px;
  -webkit-text-stroke: 1px black; /* For webkit browsers */
`;
export const StyledModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 700px;

  border-radius: 15px;
  border: 1px solid #000;
  background: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;

  @media (max-width: 1023px) {
    padding: 20px;
  }
`;
export const ClaimButton = styled.button<{ isLoading?: boolean }>`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #6d83ed;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  margin: 10px 10px;
  opacity: ${(props) => (props.isLoading ? 0.7 : 1)};
  cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};

  @media (max-width: 1023px) {
    padding: 5px 10px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const CongratsHeading = styled.h3`
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.088px;
  text-align: center;
  -webkit-text-stroke: 1px #000;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    font-size: 24px;
  }
`;
export const ClaimButtonText = styled.p`
  color: #fff;
  -webkit-text-stroke: 1px #000;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 0.088px;

  @media (max-width: 1023px) {
    font-size: 20px;
  }
`;
export const CongratsSubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;
export const CongratsSubText = styled.p`
  color: #fff;
  -webkit-text-stroke: 1px #000;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 0.088px;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;
export const StyledFruitImage = styled.img`
  width: 216px;
  height: 216px;
`;
export const Multiplier = styled.div`
  background-color: #554bd1;
  border: 1px solid black;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 5px 10px;
  z-index: 97;
  position: absolute;
  top: 10px;
  right: -5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 6px;
`;
export const HourGlassTimerBox = styled.div`
  z-index: 97;
  position: absolute;
  top: 10px;
  right: -5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const TwoButtonBox = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
  }
`;
