import { Link } from "react-router-dom";
import styled from "styled-components";
import PlayAudio from "../../components/PlayAudio";
import ArcadeMusic from "../../music/arcade.wav";
import game1Img from "../../images/arcade/game1.jpg";
import game2Img from "../../images/arcade/game2.jpg";
import game3Img from "../../images/arcade/game3.jpg";
import game4Img from "../../images/arcade/game4.jpg";
import game5Img from "../../images/arcade/game5.jpg";
import game6Img from "../../images/arcade/game6.jpg";

const SimpleMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  
  a {
    padding: 10px 20px;
    background: #ff8c42;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
    &:hover {
      background: #ff7324;
      transform: translateY(-1px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    transition: all 0.2s ease;
  }
`;

const ArcadeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    bottom: 30px;
    padding: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GameCard = styled.div<{ isAvailable: boolean }>`
  position: relative;
  border-radius: 10px;
  border: 2px solid ${props => props.isAvailable ? '#90a9aa' : '#4a4a4a'};
  background: ${props => props.isAvailable ? 'rgba(144, 169, 170, 0.1)' : 'rgba(109, 109, 109, 0.1)'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: all 0.3s ease;
  aspect-ratio: 16/9;

  &:hover {
    transform: ${props => props.isAvailable ? 'translateY(-5px)' : 'none'};
    cursor: ${props => props.isAvailable ? 'pointer' : 'not-allowed'};
    box-shadow: ${props => props.isAvailable ? '0 8px 16px rgba(0, 0, 0, 0.4)' : '0 4px 8px rgba(0, 0, 0, 0.3)'};
  }
`;

const GameImage = styled.img<{ isAvailable: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: ${props => props.isAvailable ? 'none' : 'grayscale(100%) blur(4px)'};
  opacity: ${props => props.isAvailable ? '1' : '0.5'};
  display: block;
`;

const GameTitle = styled.h3<{ isAvailable: boolean }>`
  color: white;
  text-align: center;
  padding: 0.5rem;
  margin: 0;
  -webkit-text-stroke: 0.5px black;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.088px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  filter: ${props => props.isAvailable ? 'none' : 'blur(4px)'};
  opacity: ${props => props.isAvailable ? '1' : '0.7'};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0.3rem;
  }
`;

const LockOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  -webkit-text-stroke: 1px black;
  
  &::before {
    content: "🔒";
    font-size: 32px;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    
    &::before {
      font-size: 24px;
      margin-bottom: 5px;
    }
  }
`;

const games = [
  {
    id: 1,
    title: "Flappy Sandeep",
    image: game1Img,
    link: "https://flappy.arkadiapark.com/",
    available: false
  },
  {
    id: 2,
    title: "Cupcake Affair",
    image: game2Img,
    link: "https://cupcake.arkadiapark.com/ ",
    available: false
  },
  {
    id: 3,
    title: "Gone Fishing",
    image: game3Img,
    link: "https://fishing.arkadiapark.com/ ",
    available: false
  },
  {
    id: 4,
    title: "Fren Jump",
    image: game4Img,
    link: "https://skale.arkadiapark.com/",
    available: false
  },
  {
    id: 5,
    title: "Saiyan Pepe Trainer",
    image: game5Img,
    link: "https://spepe.arkadiapark.com/ ",
    available: false
  },
  {
    id: 6,
    title: "Partner Pinball",
    image: game6Img,
    link: "/arcade/game6",
    available: false
  }
];

export function Arcade({ isMobile }: { isMobile: boolean }) {
  return (
    <main className="main">
      <div className="ArcadeBgImgContainer">
        <SimpleMenu>
          <Link to="/">← Back to Map</Link>
        </SimpleMenu>
        <ArcadeGrid>
          {games.map((game) => (
            <GameCard key={game.id} isAvailable={game.available}>
              {game.available ? (
                <a href={game.link} target="_blank" rel="noopener noreferrer">
                  <GameImage src={game.image} alt={game.title} isAvailable={game.available} />
                  <GameTitle isAvailable={game.available}>{game.title}</GameTitle>
                </a>
              ) : (
                <>
                  <GameImage src={game.image} alt={game.title} isAvailable={game.available} />
                  <GameTitle isAvailable={game.available}>{game.title}</GameTitle>
                  <LockOverlay>Locked</LockOverlay>
                </>
              )}
            </GameCard>
          ))}
        </ArcadeGrid>
      </div>
      <PlayAudio music={ArcadeMusic} />
    </main>
  );
}
