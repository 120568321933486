import { useAddress, useContract, useOwnedNFTs } from "@thirdweb-dev/react";
import NftDataAbi from "../../config/abi/nftData.json";
import { NFT_DATA_CONTRACT_ADDRESS } from "../../config/config";

export function useNftData() {
  const address = useAddress();
  const { contract } = useContract(NFT_DATA_CONTRACT_ADDRESS, NftDataAbi);

  const { data, isLoading, error } = useOwnedNFTs(contract, address);

  console.log("useNftData: ", data);

  return {
    data,
    address,
    isLoading,
    error,
  };
}
