import { useState, createRef } from "react";
import ThemeMusic from "../../music/map.wav";
import Speaker from "../../images/map/speaker.png";
import SpeakerMuted from "../../images/map/speaker-mute.png";

const BgMusic = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = createRef();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="bg-music-controls">
      <audio src={ThemeMusic} ref={audioRef} />
      <button className={!isPlaying ? "muted" : ""} onClick={togglePlay}>
        {isPlaying ? (
          <img src={Speaker} alt="Speaker" />
        ) : (
          <img src={SpeakerMuted} alt="Speaker muted" />
        )}
      </button>
    </div>
  );
};

export default BgMusic;
