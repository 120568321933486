import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAAv9B2sF7XoXB3UtM3Sz5EfCIhVIA4RSs",
  authDomain: "partner-form-7bf49.firebaseapp.com",
  projectId: "partner-form-7bf49",
  storageBucket: "partner-form-7bf49.firebasestorage.app",
  messagingSenderId: "939743823243",
  appId: "1:939743823243:web:8bfca6635d0e0a7b11db8e",
  measurementId: "G-5ZBNZ4H4SK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
