import Menu from "./components/Menu";
import { useAddress, useBalance } from "@thirdweb-dev/react";
import { Home } from "./components/Home";
import styled from "styled-components";
import PlayAudio from "../../components/PlayAudio";
import PurpleForestMusic from "../../music/purpleForest.wav";

const ConnectPage = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const ConnectHeading = styled.h1`
  margin: 50px;
  text-align: center;
  font-size: 44px;
  color: white;
`;

const Banner = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
  background-color: #6d83ed;
  padding: 8px 15px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
`;

export function PurpleForest({ isMobile }: { isMobile: boolean }) {
  const address = useAddress();
  const balance = useBalance();
  const balanceInWei = balance.data?.value.toString();

  return (
    <main className="main">
      <div className="PurpleBgImgContainer">
        <Menu />
        {isMobile && <Banner>You can add this App to the Home screen</Banner>}
        {address && balanceInWei ? (
          <Home account={address} balance={balanceInWei} />
        ) : (
          <ConnectPage>
            <ConnectHeading>Please connect your account</ConnectHeading>
          </ConnectPage>
        )}
      </div>
      <PlayAudio music={PurpleForestMusic} />
    </main>
  );
}
