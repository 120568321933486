import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BackButtonImage from "../../../images/salon/navbar/backButton.png";
import NextButtonImage from "../../../images/salon/navbar/nextButton.png";
import NavSubLongLeftImage from "../../../images/salon/navbar/navSubLongHeadingLeft.png";
import NavSubLongRightImage from "../../../images/salon/navbar/navSubLongHeadingRight.png";
import NavSubLeftImage from "../../../images/salon/navbar/navSubHeadingLeft.png";
import NavSubRightImage from "../../../images/salon/navbar/navSubHeadingRight.png";
import NavHeadingImage from "../../../images/salon/navbar/navHeadingWithText.png";

const NavMainContainer = styled.div``;
const NavigationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
`;
const BackButtonContainer = styled.div`
  max-width: 200px;
  width: 100%;
  display: flex;
  align-content: flex-start;

  @media (max-width: 1023px) {
    display: none;
  }
`;
const BackButton = styled.img``;

const NavHeadingContainer = styled.div`
  display: flex;
`;
const NavSubHeadingLongLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongLeft = styled.img``;

const NavSubHeadingLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLeft = styled.img``;

const NavHeadingBox = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
const NavHeading = styled.img`
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const NavSubHeadingRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingRight = styled.img``;

const NavSubHeadingLongRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongRight = styled.img``;

const Menu: React.FC = () => {
  return (
    <NavMainContainer>
      <NavigationContainer>
        <BackButtonContainer style={{ justifyContent: "flex-start" }}>
          <Link to="/">
            <BackButton src={BackButtonImage} loading="lazy" />
          </Link>
        </BackButtonContainer>

        <NavHeadingContainer>
          <NavSubHeadingLongLeftBox>
            <NavSubHeadingLongLeft src={NavSubLongLeftImage} loading="lazy" />
          </NavSubHeadingLongLeftBox>

          <NavSubHeadingLeftBox>
            <NavSubHeadingLeft src={NavSubLeftImage} />
          </NavSubHeadingLeftBox>

          <NavHeadingBox>
            <NavHeading src={NavHeadingImage} loading="lazy" />
          </NavHeadingBox>

          <NavSubHeadingRightBox>
            <NavSubHeadingRight src={NavSubRightImage} loading="lazy" />
          </NavSubHeadingRightBox>

          <NavSubHeadingLongRightBox>
            <NavSubHeadingLongRight src={NavSubLongRightImage} loading="lazy" />
          </NavSubHeadingLongRightBox>
        </NavHeadingContainer>

        <BackButtonContainer
          style={{ justifyContent: "flex-end", cursor: "not-allowed" }}
        >
          <Link to="/purple-forest">
            <BackButton src={NextButtonImage} loading="lazy" />
          </Link>
        </BackButtonContainer>
      </NavigationContainer>
    </NavMainContainer>
  );
};

export default Menu;
