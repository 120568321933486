import React, { useCallback, useEffect, useState } from "react";
import HairBox from "../../../images/salon/hero/hairBox.png";
import FrenBox from "../../../images/salon/hero/frenBox.png";
import ModalFren from "../../../images/salon/hero/modalFren.png";
import convertorContractAbi from "../../../config/abi/convertor.json";
import eip1155ContractAbi from "../../../config/abi/eip1155.json";
import BigNumber from "bignumber.js";
import {
  CLAIM_DATA,
  FREN_TOTAL_SUPPLY,
  CONVERTOR_CONTRACT_ADDRESS,
  EIP1155_CONTRACT_ADDRESS,
  eip1155ReadContract,
  ercReadContract,
  convertorReadMulticallContract,
  POLLING_TIME,
} from "../../../config/salon-config";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import Modal from "react-modal";
import {
  HairContainer,
  HairContentBox,
  LogicContainer,
  MainContainer,
  UIHeading,
  HairBalance,
  HairBalanceValue,
  HairImageBox,
  HairImage,
  FrenContainer,
  FrenImageBox,
  ConversionBox,
  FrenContentBox,
  FrenImage,
  FrenMinted,
  FrenMintedValue,
  FrenModalButton,
  MainButton,
  MainButtonText,
  ModalHeading,
  ModalHeadingBox,
  ModalImage,
  ModalImageBox,
  ModalInner,
  ModalListBox,
  ModalListLi,
  ModalListUl,
  SwapButtonContainer,
} from "./Home";
import { toast } from "react-toastify";

interface INftContractDetails {
  hairTokenId: null | string;
  minimumTokenBalanceToBurn: string;
  wigNftToMint: null | string;
  merkleProof: string[];
  quantityLimitPerWallet: string;
  pricePerToken: string;
  currency: string;
  wigTokenId: null | string;
  totalFernMinted: string;
}

export const Swap = ({ account }: { account: string }) => {
  const { contract: eip1155Contract } = useContract(
    EIP1155_CONTRACT_ADDRESS,
    eip1155ContractAbi,
  );

  const { contract: convertorContract } = useContract(
    CONVERTOR_CONTRACT_ADDRESS,
    convertorContractAbi,
  );

  const { mutateAsync: mutateAsyncSetApprovalForAll } = useContractWrite(
    eip1155Contract,
    "setApprovalForAll",
  );

  const { mutateAsync: mutateAsyncSwapTokens } = useContractWrite(
    convertorContract,
    "swapTokens",
  );

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [hairNftBalance, setHairNftBalance] = useState("0");
  const [isApproved, toggleApproved] = useState(false);
  const [approveTx, toggleApproveTx] = useState(false);
  const [mintTx, toggleMintTx] = useState(false);
  const [nftContractDetails, setNftContractDetails] =
    useState<INftContractDetails>({
      hairTokenId: null,
      minimumTokenBalanceToBurn: "0",
      wigNftToMint: null,
      merkleProof: [""],
      quantityLimitPerWallet: "0",
      pricePerToken: "0",
      currency: "0",
      wigTokenId: null,
      totalFernMinted: "0",
    });

  const mintWigNft = async () => {
    try {
      toggleMintTx(true);
      await mutateAsyncSwapTokens({
        args: [],
      });

      if (!nftContractDetails.hairTokenId) return;
      const getHairBalance = await eip1155ReadContract.balanceOf(
        account,
        nftContractDetails.hairTokenId,
      );
      setHairNftBalance(getHairBalance.toString());
      toggleMintTx(false);
      toast("Success", {
        type: "success",
      });
    } catch (error) {
      toast("Error", {
        type: "error",
      });
      toggleMintTx(false);
      console.log("Error in mintWigNft(swap): ", error);
    }
  };
  const approveNftContract = async () => {
    try {
      toggleApproveTx(true);
      await mutateAsyncSetApprovalForAll({
        args: [CONVERTOR_CONTRACT_ADDRESS, true],
      });
      toggleApproved(true);
      toggleApproveTx(false);
      toast("Success", {
        type: "success",
      });
    } catch (error) {
      toast("Error", {
        type: "error",
      });
      toggleApproveTx(false);
      console.log("Error in approveNftContract: ", error);
    }
  };

  const getAccountDetails = useCallback(async () => {
    try {
      if (account) {
        if (nftContractDetails.hairTokenId) {
          const getHairBalance = await eip1155ReadContract.balanceOf(
            account,
            nftContractDetails.hairTokenId,
          );
          setHairNftBalance(getHairBalance.toString());
        }
        const isApproved = await eip1155ReadContract.isApprovedForAll(
          account,
          CONVERTOR_CONTRACT_ADDRESS,
        );
        toggleApproved(isApproved);
      }
    } catch (error) {
      console.log("Error in getAccountDetails: ");
    }
  }, [account, nftContractDetails.hairTokenId]);
  const getNftContractDetails = useCallback(async () => {
    try {
      const multicallCalls = [
        convertorReadMulticallContract.hairTokenId(),
        convertorReadMulticallContract.minimumTokenBalanceToBurn(),
        convertorReadMulticallContract.wigNftToMint(),
        convertorReadMulticallContract.wigTokenId(),
      ];
      const nftDetailsResp = await Promise.all(multicallCalls);

      if (nftDetailsResp) {
        const [
          hairTokenId,
          minimumTokenBalanceToBurn,
          wigNftToMint,
          wigTokenId,
        ] = nftDetailsResp;
        // TODO: check mistake [0] -> not an array
        const getTotalFernMinted = await eip1155ReadContract.totalSupply(
          wigTokenId.toString(),
        );
        if (
          CLAIM_DATA.currency.toLowerCase() ===
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          // TODO: check mistake [0] -> not an array
          setNftContractDetails({
            hairTokenId: hairTokenId.toString(),
            minimumTokenBalanceToBurn: minimumTokenBalanceToBurn.toString(),
            wigNftToMint: wigNftToMint.toString(),
            merkleProof: CLAIM_DATA.merkleProof,
            quantityLimitPerWallet: CLAIM_DATA.quantityLimitPerWallet,
            currency: CLAIM_DATA.currency,
            totalFernMinted: getTotalFernMinted.toString(),
            wigTokenId: wigTokenId.toString(),
            pricePerToken:
              new BigNumber(CLAIM_DATA.pricePerToken.toString())
                .dividedBy(10 ** 18)
                .toString() || "0",
          });
        } else {
          // TODO: check mistake [0] -> not an array
          setNftContractDetails({
            hairTokenId: hairTokenId.toString(),
            minimumTokenBalanceToBurn: minimumTokenBalanceToBurn.toString(),
            wigNftToMint: wigNftToMint.toString(),
            merkleProof: CLAIM_DATA.merkleProof,
            quantityLimitPerWallet: CLAIM_DATA.quantityLimitPerWallet,
            pricePerToken: CLAIM_DATA.pricePerToken,
            currency: CLAIM_DATA.currency,
            totalFernMinted: getTotalFernMinted.toString(),
            wigTokenId: wigTokenId.toString(),
          });
        }
        if (
          CLAIM_DATA.currency.toLowerCase() !==
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          // TODO: do we need this call? as native tokens decimals = 18
          // TODO: check mistake [0] -> not an array
          const decimals = await ercReadContract.decimals();
          setNftContractDetails((nftData: any) => ({
            ...nftData,
            pricePerToken:
              new BigNumber(CLAIM_DATA.pricePerToken.toString())
                .dividedBy(10 ** decimals)
                .toString() || "0",
          }));
        }
      }
    } catch (e) {
      console.log("Error in getNftContractDetails: ");
    }
  }, []);

  useEffect(() => {
    getNftContractDetails();
    if (account) {
      getAccountDetails();
    }

    const init = setInterval(() => {
      getNftContractDetails();
      if (account) {
        getAccountDetails();
      }
    }, POLLING_TIME);

    return () => clearInterval(init);
  }, [account, getAccountDetails, getNftContractDetails]);

  return (
    <LogicContainer>
      <UIHeading>Purple Fren Swap</UIHeading>
      <MainContainer>
        <HairContainer>
          <HairContentBox>
            <HairBalance>Balance</HairBalance>
            <HairBalanceValue>{hairNftBalance}</HairBalanceValue>
          </HairContentBox>
          <HairImageBox>
            <HairImage src={HairBox} alt="Hair NFT" loading="lazy" />
          </HairImageBox>
        </HairContainer>
        <FrenContainer>
          <FrenImageBox>
            <FrenImage src={FrenBox} alt="Fren NFT" loading="lazy" />
          </FrenImageBox>
          <FrenContentBox>
            <FrenMinted>Minted</FrenMinted>
            <FrenMintedValue>
              {nftContractDetails.totalFernMinted} / {FREN_TOTAL_SUPPLY}
            </FrenMintedValue>
            <FrenModalButton onClick={() => setIsModalOpen(true)}>
              ?
            </FrenModalButton>
          </FrenContentBox>
        </FrenContainer>
        <ConversionBox>
          {nftContractDetails.minimumTokenBalanceToBurn} HAIR = 1 FREN
        </ConversionBox>
      </MainContainer>
      <SwapButtonContainer>
        {isApproved ? (
          <MainButton
            onClick={mintWigNft}
            disabled={
              mintTx ||
              parseFloat(nftContractDetails.minimumTokenBalanceToBurn) >
                parseFloat(hairNftBalance) ||
              parseFloat(hairNftBalance) === 0
            }
          >
            <MainButtonText>{mintTx ? "Processing..." : "Swap"}</MainButtonText>
          </MainButton>
        ) : (
          <MainButton
            onClick={approveNftContract}
            disabled={
              approveTx ||
              parseFloat(nftContractDetails.minimumTokenBalanceToBurn) >
                parseFloat(hairNftBalance)
            }
          >
            <MainButtonText>
              {approveTx ? "Processing..." : "Approve"}
            </MainButtonText>
          </MainButton>
        )}
      </SwapButtonContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        aria-labelledby="The Purple Fren"
        aria-describedby="Information about the purple fren"
      >
        <ModalInner>
          <ModalHeadingBox>
            <ModalHeading>The Purple Fren</ModalHeading>
          </ModalHeadingBox>
          <ModalImageBox>
            <ModalImage
              src={ModalFren}
              alt="The Purple Fren NFT"
              loading="lazy"
            />
          </ModalImageBox>
          <ModalListBox>
            <ModalListUl>
              Your FREN will:
              <ModalListLi>Water ALL Garden</ModalListLi>
              <ModalListLi>2X Lucky Drop Chance</ModalListLi>
              <ModalListLi>Save Forest from Burning</ModalListLi>
            </ModalListUl>
          </ModalListBox>
          <MainButton onClick={() => setIsModalOpen(false)}>
            <MainButtonText>Close</MainButtonText>
          </MainButton>
        </ModalInner>
      </Modal>
    </LogicContainer>
  );
};
