import { useState, createRef } from "react";
import Speaker from "../images/map/speaker.png";
import SpeakerMuted from "../images/map/speaker-mute.png";
import styles from "./playAudio.module.css";
import styled from "styled-components";

const AudioContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 1);
  }
`;

const PlayAudio = ({ music }: { music: any }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = createRef<HTMLAudioElement>();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={styles.container}>
      <audio src={music} ref={audioRef} />
      <AudioContainer onClick={togglePlay}>
        {isPlaying ? (
          <img className={styles.playImg} src={Speaker} alt="Speaker" />
        ) : (
          <img
            className={styles.playImg}
            src={SpeakerMuted}
            alt="Speaker muted"
          />
        )}
      </AudioContainer>
    </div>
  );
};

export default PlayAudio;
