import {
  SEED_GARDEN_CONTRACT_ADDRESS,
  TREE_GARDEN_CONTRACT_ADDRESS,
} from "../../config/purple-config";
import {
  SeedGarden__factory,
  TreeGarden__factory,
} from "../../config/abi/types";
import { ISeedOrTreeInfo } from "./index";
import { MulticallWrapper } from "ethers-multicall-provider";
import { JSON_RPC_PROVIDER } from "../../config/config";

export const fetchSeedsAndTrees = async (acc: string) => {
  try {
    const multicallProvider = MulticallWrapper.wrap(JSON_RPC_PROVIDER);

    const seedGardenMulticallContract = SeedGarden__factory.connect(
      SEED_GARDEN_CONTRACT_ADDRESS,
      multicallProvider,
    );

    const allSeedIds = await seedGardenMulticallContract.getIdList(acc);
    let allSeedIdsFormatted = allSeedIds.toString().split(",");
    if (allSeedIdsFormatted[0] === "") allSeedIdsFormatted = [];

    const getSeedIdListCalls = [];
    for (let i = 0; i < allSeedIdsFormatted.length; i++) {
      getSeedIdListCalls.push(
        seedGardenMulticallContract.idToSeeds(allSeedIdsFormatted[i]),
      );
    }
    const getSeedIdListCallsResp = await Promise.all(getSeedIdListCalls);

    const getSeedNextWaterTimeCalls = [];
    for (let i = 0; i < allSeedIdsFormatted.length; i++) {
      getSeedNextWaterTimeCalls.push(
        seedGardenMulticallContract.nextWaterTime(allSeedIdsFormatted[i]),
      );
    }
    const getSeedNextWaterTimeCallsResp = await Promise.all(
      getSeedNextWaterTimeCalls,
    );

    const allSeedInfo: ISeedOrTreeInfo[] = [];

    for (let i = 0; i < getSeedIdListCallsResp.length; i++) {
      const seedInfo = getSeedIdListCallsResp[i];
      const nextWaterTime = getSeedNextWaterTimeCallsResp[i];
      const formattedSeedInfo: ISeedOrTreeInfo = {
        type: "seed",
        id: allSeedIdsFormatted[i].toString(),
        gardener: seedInfo[0].toString(),
        amount: seedInfo[1].toString(),
        plantedAt: seedInfo[2].toString(),
        lastWatered: seedInfo[3].toString(),
        wateredCount: seedInfo[4].toString(),
        skipsAllowed: seedInfo[5].toString(),
        status: seedInfo[7].toString(),
        nextWaterTime: nextWaterTime.toString(),
      };
      allSeedInfo.push(formattedSeedInfo);
    }

    const treeGardenMulticallContract = TreeGarden__factory.connect(
      TREE_GARDEN_CONTRACT_ADDRESS,
      multicallProvider,
    );

    const allTreeIds = await treeGardenMulticallContract.getIdList(acc);
    let allTreeIdsFormatted = allTreeIds.toString().split(",");
    if (allTreeIdsFormatted[0] === "") allTreeIdsFormatted = [];

    const getTreeIdListCalls = [];
    for (let i = 0; i < allTreeIdsFormatted.length; i++) {
      getTreeIdListCalls.push(
        treeGardenMulticallContract.idToSeeds(allTreeIdsFormatted[i]),
      );
    }
    const getTreeIdListCallsResp = await Promise.all(getTreeIdListCalls);

    const getTreeNextWaterTimeCalls = [];
    for (let i = 0; i < allTreeIdsFormatted.length; i++) {
      getTreeNextWaterTimeCalls.push(
        treeGardenMulticallContract.nextWaterTime(allTreeIdsFormatted[i]),
      );
    }

    let getTreeNextWaterTimeCallsResp = await Promise.all(
      getTreeNextWaterTimeCalls,
    );

    const allTreeInfo: ISeedOrTreeInfo[] = [];

    for (let i = 0; i < getTreeIdListCallsResp.length; i++) {
      const treeInfo = getTreeIdListCallsResp[i];
      const nextWaterTime = getTreeNextWaterTimeCallsResp[i];
      const formattedTreeInfo: ISeedOrTreeInfo = {
        type: "tree",
        id: allTreeIdsFormatted[i].toString(),
        gardener: treeInfo[0].toString(),
        amount: treeInfo[1].toString(),
        plantedAt: treeInfo[2].toString(),
        lastWatered: treeInfo[3].toString(),
        wateredCount: treeInfo[4].toString(),
        skipsAllowed: treeInfo[5].toString(),
        status: treeInfo[7].toString(),
        nextWaterTime: nextWaterTime.toString(),
        lifetimeWateredCount: treeInfo[8].toString(),
      };
      allTreeInfo.push(formattedTreeInfo);
    }

    const seedsCount = allSeedInfo.length;
    const treesCount = allTreeInfo.length;

    return {
      allSeeds: allSeedInfo,
      seedsCount: seedsCount,
      allTrees: allTreeInfo,
      treesCount: treesCount,
    };
  } catch (e) {
    console.log("Error in fetchSeedsAndTrees: ", e);
  }
};
