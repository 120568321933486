import {
  Convertor__factory,
  Eip1155__factory,
  Erc20__factory,
  SeedGardenSalon__factory,
  SeedSalon__factory,
} from "./abi/types";
import { JSON_RPC_PROVIDER, MULTICALL_PROVIDER, NETWORK_ID } from "./config";

export const POLLING_TIME = 30_000;

export const CLAIM_DATA = {
  merkleProof: [
    "0x0000000000000000000000000000000000000000000000000000000000000000",
  ],
  quantityLimitPerWallet:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  pricePerToken: "10000000000000000",
  currency: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  decimals: 18,
};

const ADDRESSES = {
  seedGarden: {
    80001: "0x8f8b92148b6403D15Fa1AF9983460541395F41ee",
    137: "0xa7a6A0Ed99642F827fb7168DBDcA2cBe61e1BFaf",
  },
  seed: {
    80001: "0x1485716aD326fb9316Bff95f3fd53978E9D63Bf9",
    137: "0x0783a2b6B86D564956c1187C3Ff249522cd47404",
  },
  convertor: {
    80001: "0xe718d68eB8EF2660FA95E6BF51ECd93AaffD6cdE",
    137: "0x3FF1f2Af10Ab5BCeA1f725a3102D20be4200Ea1f",
  },
  eip1155: {
    80001: "0x5D4B4577333E18C5111eFbe96204e8FCFB5d1C42",
    137: "0x0783a2b6B86D564956c1187C3Ff249522cd47404",
  },
};

export const FREN_TOTAL_SUPPLY = 333;

export const SEED_GARDEN_CONTRACT_ADDRESS = ADDRESSES.seedGarden[NETWORK_ID];
export const SEED_CONTRACT_ADDRESS = ADDRESSES.seed[NETWORK_ID];
export const CONVERTOR_CONTRACT_ADDRESS = ADDRESSES.convertor[NETWORK_ID];
export const EIP1155_CONTRACT_ADDRESS = ADDRESSES.eip1155[NETWORK_ID];

export const ercReadContract = Erc20__factory.connect(
  CLAIM_DATA.currency,
  JSON_RPC_PROVIDER,
);

export const eip1155ReadContract = Eip1155__factory.connect(
  EIP1155_CONTRACT_ADDRESS,
  JSON_RPC_PROVIDER,
);

export const convertorReadMulticallContract = Convertor__factory.connect(
  CONVERTOR_CONTRACT_ADDRESS,
  MULTICALL_PROVIDER,
);

export const seedReadContract = SeedSalon__factory.connect(
  SEED_CONTRACT_ADDRESS,
  JSON_RPC_PROVIDER,
);

export const seedGardenReadContract = SeedGardenSalon__factory.connect(
  SEED_GARDEN_CONTRACT_ADDRESS,
  JSON_RPC_PROVIDER,
);

export const seedGardenReadMulticallContract = SeedGardenSalon__factory.connect(
  SEED_GARDEN_CONTRACT_ADDRESS,
  MULTICALL_PROVIDER,
);

export const seedReadMulticallContract = SeedSalon__factory.connect(
  SEED_CONTRACT_ADDRESS,
  MULTICALL_PROVIDER,
);
