import { Link } from "react-router-dom";
import styled from "styled-components";

const CastleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  position: relative;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const BackButton = styled(Link)`
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 12px 24px;
  color: #f5e6d3;
  text-decoration: none;
  z-index: 10;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.5px;
  
  /* Brown gradient texture */
  background: linear-gradient(
    45deg,
    #8B4513 0%,
    #A0522D 25%,
    #8B4513 50%,
    #A0522D 75%,
    #8B4513 100%
  );
  
  /* Wood-like texture overlay */
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  
  /* Button styling */
  border: 2px solid #D2691E;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
  /* Hover effect */
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    background:
  }
`;

export function Castle({ isMobile }: { isMobile: boolean }) {
  return (
    <main className="main">
      <CastleContainer>
        <h1>Test - Castle Page</h1>
        <BackButton to="/">← Back to Map</BackButton>
        <Video 
          autoPlay 
          loop 
          muted 
          playsInline
        >
          <source src="/videos/castle.mp4" type="video/mp4" />
          Video not supported
        </Video>
      </CastleContainer>
    </main>
  );
}
