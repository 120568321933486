import { useEffect, useState } from "react";
import HairBox from "../../../images/salon/hero/hairBox.png";
import SeedBox from "../../../images/salon/hero/seedBox.png";
import Arrow from "../../../images/salon/hero/arrow.png";
import {
  FrenContainer,
  FrenContentBox,
  FrenImage,
  FrenImageBox,
  FrenMinted,
  FrenMintedValue,
  FrenModalButton,
  HairBalance,
  HairBalanceValue,
  HairContainer,
  HairContentBox,
  HairImage,
  HairImageBox,
  LogicContainer,
  MainButton,
  MainButtonText,
  MainContainer,
  SwapButtonContainer,
  UIHeading,
} from "./Home";
import styled from "styled-components";
import {
  POLLING_TIME,
  SEED_CONTRACT_ADDRESS,
  SEED_GARDEN_CONTRACT_ADDRESS,
  seedGardenReadContract,
  seedGardenReadMulticallContract,
  seedReadContract,
} from "../../../config/salon-config";
import seedContractAbi from "../../../config/abi/seed.json";
import seedGardenContractAbi from "../../../config/abi/seedGarden.json";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import RefSuccessful from "../../../images/salon/ref-success.mp4";
import RefUnSuccessful from "../../../images/salon/ref-no-success.mp4";
import {
  ModalInner,
  ModalHeadingBox,
  ModalImageBox,
  ModalHeading,
} from "./Home";
import { HAIR_ID, SEED_ID } from "../../../config/config";
import { Tooltip } from "react-tooltip";

const ReferralConversionBox = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 800;
  z-index: 86;
  -webkit-text-stroke: 1px black;

  @media (max-width: 720px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
`;
const StyledInput = styled.input`
  padding: 15px 40px;
  border-radius: 12px;
  width: 100%;
  font-size: 24px;

  @media (max-width: 1023px) {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 6px;
  }
`;
export const ReferralModalImage = styled.video`
  max-width: 265px;
  max-height: 265px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

const DEFAULT_SEED = {
  type: "seed",
  id: "0",
  amount: "0",
  gardener: "0",
  lastWatered: "0",
  plantedAt: "0",
  skipsAllowed: "0",
  status: "0",
  wateredCount: "0",
};

export const Referral = ({ account }: { account: string }) => {
  const [plantSeedValue, setPlantSeedValue] = useState(1);
  const [hairNftBalance, setHairNftBalance] = useState("0");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSeedApproved, setIsSeedApproved] = useState(false);
  const [luckySeedBalance, setLuckySeedBalance] = useState("0");
  const [showLuckyModal, setShowLuckyModal] = useState({
    show: false,
    isLucky: false,
    seedAmount: "0",
  });
  const [seedBalance, setSeedBalance] = useState(0);
  const [seed, setSeed] = useState({
    type: "seed",
    id: "0",
    amount: "0",
    gardener: "0",
    lastWatered: "0",
    plantedAt: "0",
    skipsAllowed: "0",
    status: "2",
    wateredCount: "0",
  });
  // const [amountOfSeedsToPlant, setAmountOfSeedsToPlant] = useState(1);

  const { contract: seedWriteContract } = useContract(
    SEED_CONTRACT_ADDRESS,
    seedContractAbi,
  );
  const { contract: seedGardenWriteContract } = useContract(
    SEED_GARDEN_CONTRACT_ADDRESS,
    seedGardenContractAbi,
  );

  const { mutateAsync: mutateAsyncSeedApprove } = useContractWrite(
    seedWriteContract,
    "setApprovalForAll",
  );
  const { mutateAsync: mutateAsyncPlantSeed } = useContractWrite(
    seedGardenWriteContract,
    "plantSeed",
  );
  const { mutateAsync: mutateAsyncWaterPlant } = useContractWrite(
    seedGardenWriteContract,
    "waterPlants",
  );

  const checkSeedAllowance = async (acc: string) => {
    try {
      const seedContract = seedReadContract;
      const hairBalance = await seedContract.balanceOf(acc, HAIR_ID);
      const luckySeedBalance = await seedContract.balanceOf(acc, SEED_ID);

      const isApproved = await seedContract.isApprovedForAll(
        acc,
        SEED_GARDEN_CONTRACT_ADDRESS,
      );
      setHairNftBalance(hairBalance.toString());
      setLuckySeedBalance(luckySeedBalance.toString());
      setIsSeedApproved(isApproved);
    } catch (e) {
      console.log("Error in checkSeedAllowance: ", e);
    }
  };
  const getSeedData = async (acc: string) => {
    try {
      const seedGardenContract = seedGardenReadMulticallContract;
      const allSeedIds = await seedGardenContract.getIdList(acc);
      let allSeedIdsFormatted = allSeedIds.toString().split(",");
      if (allSeedIdsFormatted[0] === "") allSeedIdsFormatted = [];
      if (allSeedIdsFormatted.length > 0) {
        const multicallCalls = [];
        for (let i = 0; i < allSeedIdsFormatted.length; i++) {
          multicallCalls.push(
            seedGardenContract.idToSeeds(allSeedIdsFormatted[i]),
          );
        }

        const multicallResp = await Promise.all(multicallCalls);

        const formattedSeedsInfo = [];

        for (let i = 0; i < multicallResp.length; i++) {
          const seedInfo = multicallResp[i];

          const formattedSeedInfo = {
            type: "seed",
            id: allSeedIdsFormatted[i].toString(),

            gardener: seedInfo[0].toString(),
            amount: seedInfo[1].toString(),
            plantedAt: seedInfo[2].toString(),
            lastWatered: seedInfo[3].toString(),
            wateredCount: seedInfo[4].toString(),
            skipsAllowed: seedInfo[5].toString(),
            status: seedInfo[6].toString(),
          };
          formattedSeedsInfo.push(formattedSeedInfo);
        }

        const activeSeeds = formattedSeedsInfo.filter(
          (seed) => seed.status !== "2" && seed.status !== "4",
        );

        if (activeSeeds.length > 0) {
          const activeSeed = activeSeeds[0];
          setSeed(activeSeed);
          setSeedBalance(allSeedIdsFormatted.length);
        } else {
          setSeed(DEFAULT_SEED);
          setSeedBalance(allSeedIdsFormatted.length);
        }
      }
    } catch (e) {
      console.log("Error in getSeedData: ", e);
    }
  };

  const handleSeedApprove = async () => {
    try {
      setIsButtonLoading(true);
      await mutateAsyncSeedApprove({
        args: [SEED_GARDEN_CONTRACT_ADDRESS, true],
      });
      setIsButtonLoading(false);
      setIsSeedApproved(true);
      toast("Success", {
        type: "success",
      });
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      setIsButtonLoading(false);
      console.log("Error in handleSeedApprove: ", e);
    }
  };
  const handlePlantSeed = async () => {
    try {
      setIsButtonLoading(true);
      await mutateAsyncPlantSeed({
        args: [plantSeedValue.toString()],
      });
      toast("Success", {
        type: "success",
      });
      if (account) {
        await checkSeedAllowance(account);
        await getSeedData(account);
      }
      setIsButtonLoading(false);
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      setIsButtonLoading(false);
      console.log("Error in handlePlantSeed: ", e);
    }
  };
  const handleWaterPlant = async () => {
    try {
      setIsButtonLoading(true);
      const seedId = seed.id;
      await mutateAsyncWaterPlant({
        args: [seedId],
      });
      const status = await seedGardenReadContract.getPlantStatus(seedId);
      if (status === 4) {
        setShowLuckyModal({
          show: true,
          seedAmount: seed.amount,
          isLucky: true,
        });
      } else {
        setShowLuckyModal({
          show: true,
          seedAmount: "0",
          isLucky: false,
        });
      }
      toast("Success", {
        type: "success",
      });
      if (account) {
        await checkSeedAllowance(account);
        await getSeedData(account);
      }
      setIsButtonLoading(false);
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      setIsButtonLoading(false);
      console.log("Error in handleWaterPlant: ", e);
    }
  };

  useEffect(() => {
    if (account) {
      checkSeedAllowance(account);
      getSeedData(account);
    }

    const init = setInterval(() => {
      if (account) {
        checkSeedAllowance(account);
        getSeedData(account);
      }
    }, POLLING_TIME);

    return () => clearInterval(init);
  }, [account]);

  return (
    <LogicContainer>
      <UIHeading>Lucky Hair Burn</UIHeading>
      <MainContainer>
        <HairContainer>
          <HairContentBox>
            <HairBalance>Balance</HairBalance>
            <HairBalanceValue>{hairNftBalance}</HairBalanceValue>
            <FrenModalButton
              data-tooltip-id="salon-lucky-hair-burn"
              data-tooltip-content="There is a 1% chance to transform you hair into a seed"
              data-tooltip-place="top"
            >
              ?
            </FrenModalButton>
            <Tooltip id="salon-lucky-hair-burn" />
          </HairContentBox>
          <HairImageBox>
            <HairImage src={HairBox} alt="Hair NFT" loading="lazy" />
          </HairImageBox>
        </HairContainer>
        <FrenContainer>
          <FrenImageBox>
            <FrenImage src={SeedBox} alt="Seed NFT" loading="lazy" />
          </FrenImageBox>
          <FrenContentBox>
            <FrenMinted>Committed</FrenMinted>
            <FrenMintedValue>{seed.amount}</FrenMintedValue>
            <FrenMintedValue style={{ marginTop: "10px" }}>
              {luckySeedBalance}
            </FrenMintedValue>
            <FrenMinted>Balance</FrenMinted>
          </FrenContentBox>
        </FrenContainer>
        <ReferralConversionBox>
          <img src={Arrow} alt="Arrow" loading="lazy" />
        </ReferralConversionBox>
      </MainContainer>
      <SwapButtonContainer>
        <>
          {isSeedApproved &&
            Number(hairNftBalance) > 0 &&
            Number(seed.amount) <= 0 && (
              <>
                <div>
                  <StyledInput
                    value={plantSeedValue}
                    min={1}
                    max={Number(hairNftBalance)}
                    step={1}
                    type="number"
                    placeholder="Number of hairs to commit"
                    onChange={(e) => setPlantSeedValue(Number(e.target.value))}
                  />
                </div>
              </>
            )}
        </>
        {isSeedApproved ? (
          <>
            <ButtonContainer>
              {Number(hairNftBalance) > 0 && Number(seed.amount) <= 0 && (
                <MainButton
                  onClick={handlePlantSeed}
                  disabled={account === undefined || isButtonLoading}
                >
                  <MainButtonText>
                    {isButtonLoading ? "Processing..." : "Commit Hair"}
                  </MainButtonText>
                </MainButton>
              )}

              {seedBalance > 0 &&
                Number(seed.amount) > 0 &&
                seed &&
                seed.status !== "2" &&
                seed.status !== "4" && (
                  <MainButton
                    onClick={handleWaterPlant}
                    disabled={account === undefined || isButtonLoading}
                  >
                    <MainButtonText>
                      {isButtonLoading ? "Processing..." : "Check if you won"}
                    </MainButtonText>
                  </MainButton>
                )}
            </ButtonContainer>
          </>
        ) : (
          <MainButton
            onClick={handleSeedApprove}
            disabled={account === undefined || isButtonLoading}
          >
            <MainButtonText>
              {isButtonLoading ? "Processing..." : "Approve"}
            </MainButtonText>
          </MainButton>
        )}
      </SwapButtonContainer>
      <Modal
        isOpen={showLuckyModal.show}
        onRequestClose={() =>
          setShowLuckyModal({ ...showLuckyModal, show: false })
        }
        aria-labelledby="Water Plant status modal"
        aria-describedby="Is lucky or not"
      >
        <ModalInner>
          <ModalHeadingBox>
            <ModalHeading>
              {showLuckyModal.isLucky ? "Congratulations!" : "We are sorry! "}
            </ModalHeading>
          </ModalHeadingBox>
          <ModalImageBox>
            <ReferralModalImage
              src={showLuckyModal.isLucky ? RefSuccessful : RefUnSuccessful}
              autoPlay
              loop
              muted
              playsInline
              controls={false}
            />
          </ModalImageBox>
          {showLuckyModal.isLucky ? (
            <ModalHeading>You won {seed.amount} seeds</ModalHeading>
          ) : (
            <ModalHeading>You didn't win any seeds! 🙁</ModalHeading>
          )}
          <MainButton
            onClick={() =>
              setShowLuckyModal({ ...showLuckyModal, show: false })
            }
          >
            <MainButtonText>Close</MainButtonText>
          </MainButton>
        </ModalInner>
      </Modal>
    </LogicContainer>
  );
};
