import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { fetchSeedsAndTrees } from "./fetchSeedsAndTrees";

export interface ISeedOrTreeInfo {
  type: "seed" | "tree";
  id: string;
  gardener: string;
  amount: string;
  plantedAt: string;
  lastWatered: string;
  wateredCount: string;
  skipsAllowed: string;
  status: string;
  nextWaterTime: string;
  lifetimeWateredCount?: string;
}

const initialState = {
  data: {
    allSeeds: [] as ISeedOrTreeInfo[],
    seedsCount: 0,
    allTrees: [] as ISeedOrTreeInfo[],
    treesCount: 0,
  },
};

const dashboardSlice = createSlice({
  name: "seedsAndTrees",
  initialState,
  reducers: {
    setSeedsAndTreesDataAction: (
      state,
      action: PayloadAction<{
        allSeeds: ISeedOrTreeInfo[];
        seedsCount: number;
        allTrees: ISeedOrTreeInfo[];
        treesCount: number;
      }>,
    ) => {
      state.data.allSeeds = action.payload.allSeeds;
      state.data.seedsCount = action.payload.seedsCount;
      state.data.allTrees = action.payload.allTrees;
      state.data.treesCount = action.payload.treesCount;
    },
  },
});

const { setSeedsAndTreesDataAction } = dashboardSlice.actions;

export const setSeedsAndTreesAsync =
  (account: string) => async (dispatch: Dispatch) => {
    const info = await fetchSeedsAndTrees(account);
    if (info) {
      dispatch(setSeedsAndTreesDataAction(info));
    }
  };

export default dashboardSlice.reducer;
