import {
  ANCIENT_TREE_ID,
  SEED_ADDRESS,
  SEED_GARDEN_CONTRACT_ADDRESS,
  TREE_ADDRESS,
  TREE_GARDEN_CONTRACT_ADDRESS,
  TREE_ID,
} from "../../config/purple-config";
import { Seed__factory, Tree__factory } from "../../config/abi/types";
import { MulticallWrapper } from "ethers-multicall-provider";
import {
  FREN_ID,
  FRUIT_ID,
  JSON_RPC_PROVIDER,
  SEED_ID,
} from "../../config/config";

export const fetchDashboardValues = async (account: string) => {
  try {
    const seedAddress = SEED_ADDRESS;
    const seedGardenAddress = SEED_GARDEN_CONTRACT_ADDRESS;
    const treeAddress = TREE_ADDRESS;
    const treeGardenAddress = TREE_GARDEN_CONTRACT_ADDRESS;

    const multicallProvider = MulticallWrapper.wrap(JSON_RPC_PROVIDER);
    const seedMulticallContract = Seed__factory.connect(
      seedAddress,
      multicallProvider,
    );
    const treeMulticallContract = Tree__factory.connect(
      treeAddress,
      multicallProvider,
    );

    const [
      totalSeeds,
      totalFrens,
      totalFruits,
      userBalanceOfSeeds,
      userBalanceOfFrens,
      userBalanceOfFruits,
      seedApproval,
    ] = await Promise.all([
      seedMulticallContract.totalSupply(SEED_ID),
      seedMulticallContract.totalSupply(FREN_ID),
      seedMulticallContract.totalSupply(FRUIT_ID),
      seedMulticallContract.balanceOf(account, SEED_ID),
      seedMulticallContract.balanceOf(account, FREN_ID),
      seedMulticallContract.balanceOf(account, FRUIT_ID),
      seedMulticallContract.isApprovedForAll(account, seedGardenAddress),
    ]);

    const [
      totalTrees,
      userBalanceOfTrees,
      treeApproval,
      totalAncientTrees,
      userBalanceOfAncientTrees,
    ] = await Promise.all([
      treeMulticallContract.totalSupply(TREE_ID),
      treeMulticallContract.balanceOf(account, TREE_ID),
      treeMulticallContract.isApprovedForAll(account, treeGardenAddress),
      treeMulticallContract.totalSupply(ANCIENT_TREE_ID),
      treeMulticallContract.balanceOf(account, ANCIENT_TREE_ID),
    ]);

    let isSeedApproved = false;
    const seedApprovalString = seedApproval.toString();
    if (seedApprovalString === "true") {
      isSeedApproved = true;
    }

    let isTreeApproved = false;
    const treeApprovalString = treeApproval.toString();
    if (treeApprovalString === "true") {
      isTreeApproved = true;
    }

    return {
      isSeedApproved: isSeedApproved,
      isTreeApproved: isTreeApproved,

      totalSupplyOfSeeds: totalSeeds.toString(),
      totalSupplyOfFrens: totalFrens.toString(),
      totalSupplyOfFruits: totalFruits.toString(),
      totalSupplyOfTrees: totalTrees.toString(),
      totalSupplyOfAncientTrees: totalAncientTrees.toString(),

      balanceOfSeeds: userBalanceOfSeeds.toString(),
      balanceOfFrens: userBalanceOfFrens.toString(),
      balanceOfFruits: userBalanceOfFruits.toString(),
      balanceOfTrees: userBalanceOfTrees.toString(),
      balanceOfAncientTrees: userBalanceOfAncientTrees.toString(),
    };
  } catch (e) {
    console.log("Error in fetchDashboardValues: ", e);
  }
};
