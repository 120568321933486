import styled from "styled-components";
import { Referral } from "./Referral";
import { Swap } from "./Swap";

export const MainButton = styled.button`
  padding: 10px 40px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  -webkit-text-stroke: 1px black;

  &:hover {
    cursor: pointer;
    background: purple;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
export const MainButtonText = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 0.088px;
`;
export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 100px 20px 0 20px;
  padding-bottom: 100px;
  flex-wrap: wrap;
`;
export const LearnMoreBox = styled.div`
  padding: 10px 40px;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
  margin: 0 80px;

  @media (max-width: 720px) {
    margin: 0 5px;
  }
`;
export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  position: relative;
`;
export const HairContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;
export const HairContentBox = styled.div`
  margin-top: 50px;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;
export const HairBalance = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
export const HairBalanceValue = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
export const HairImageBox = styled.div`
  z-index: 85;
`;
export const HairImage = styled.img``;
export const FrenContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const FrenContentBox = styled.div`
  margin-top: 50px;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;
export const FrenMinted = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
export const FrenMintedValue = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`;
export const FrenModalButton = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
export const FrenImageBox = styled.div`
  z-index: 85;
`;
export const FrenImage = styled.img``;
export const ConversionBox = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 800;
  z-index: 86;
  -webkit-text-stroke: 1px black;
`;
export const SwapButtonContainer = styled.div``;
export const LogicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  flex-direction: column;
  margin-bottom: 100px;

  @media (max-width: 720px) {
    margin-top: 100px;
  }
`;
export const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 88;

  max-width: 388px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`;
export const ModalHeadingBox = styled.div``;
export const ModalHeading = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  -webkit-text-stroke: 1px black;
`;
export const ModalImageBox = styled.div``;
export const ModalImage = styled.img``;
export const ModalListBox = styled.div`
  color: #fff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 23px;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
`;
export const ModalListUl = styled.ul`
  line-height: 30px;
`;
export const ModalListLi = styled.li`
  font-size: 18px;
  line-height: 25px;
`;
export const UIHeading = styled.h2`
  margin: 50px 5px;
  text-align: center;
  color: white;
  font-size: 45px;
  font-weight: 800;
  -webkit-text-stroke: 1px black;

  @media (max-width: 720px) {
    font-size: 30px;
    margin: 20px 5px 5px 5px;
  }
`;

export const Home = ({ account }: { account: string }) => {
  return (
    <>
      <Swap account={account} />
      <Referral account={account} />
      <FooterContainer>
        <a
          href="https://discord.gg/9bZ7acNWJA"
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          <LearnMoreBox>Learn More</LearnMoreBox>
        </a>
      </FooterContainer>
    </>
  );
};
