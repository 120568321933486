import { useCallback, useEffect, useState } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { Contract, ethers } from "ethers";
import {
  SEED_CONTRACT_ADDRESS,
  seedReadMulticallContract,
} from "../config/fruit-config";
import seedAbi from "../config/abi/seed.json";
import { Seed } from "../config/abi/types";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import {
  FREN_ID,
  FRUIT_ID,
  GLOWY_BAG_ID,
  HAIR_ID,
  SEED_ID,
} from "../config/config";

const ConnectButtonContainer = styled.div`
  background-color: purple;
  border-radius: 12px;
  padding: 5px;
`;
const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  flex-wrap: wrap;
`;

const BalanceText = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  -webkit-text-stroke: 0.4px black;
  margin: 5px;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const ClaimButton = styled.button<{ isLoading?: boolean }>`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #6d83ed;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  margin: 10px 10px;
  opacity: ${(props) => (props.isLoading ? 0.7 : 1)};
  cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};

  @media (max-width: 1023px) {
    padding: 5px 10px;
  }

  &:hover {
    background: #5c74eb;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ClaimButtonText = styled.p`
  color: #fff;
  -webkit-text-stroke: 1px #000;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 0.088px;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;

const SectionHeading = styled.h3`
  color: white;
  font-size: 24px;
  margin-top: 40px;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const TransferAssets = ({
  smartWalletAddress,
}: {
  smartWalletAddress: string;
}) => {
  const { address: externalWalletAddress, isConnected } = useWeb3ModalAccount();
  const { walletProvider: externalWalletProvider } = useWeb3ModalProvider();

  const [externalWalletBalances, setExternalWalletBalances] = useState({
    seed: "0",
    fruit: "0",
    fren: "0",
    hair: "0",
    glowyBag: "0",
  });

  const [smartWalletBalances, setSmartWalletBalances] = useState({
    seed: "0",
    fruit: "0",
    fren: "0",
    hair: "0",
    glowyBag: "0",
  });
  const [isTransferring, setIsTransferring] = useState(false);

  const { contract: seedWriteContract } = useContract(
    SEED_CONTRACT_ADDRESS,
    seedAbi,
  );
  const { mutateAsync: mutateAsyncSeedSafeBatchTransfer } = useContractWrite(
    seedWriteContract,
    "safeBatchTransferFrom",
  );

  const getBalances = useCallback(
    async (eoaAddress: `0x${string}`) => {
      try {
        const multicallCalls = [
          seedReadMulticallContract.balanceOf(eoaAddress, SEED_ID),
          seedReadMulticallContract.balanceOf(eoaAddress, HAIR_ID),
          seedReadMulticallContract.balanceOf(eoaAddress, FREN_ID),
          seedReadMulticallContract.balanceOf(eoaAddress, FRUIT_ID),
          seedReadMulticallContract.balanceOf(eoaAddress, GLOWY_BAG_ID),

          seedReadMulticallContract.balanceOf(smartWalletAddress, SEED_ID),
          seedReadMulticallContract.balanceOf(smartWalletAddress, HAIR_ID),
          seedReadMulticallContract.balanceOf(smartWalletAddress, FREN_ID),
          seedReadMulticallContract.balanceOf(smartWalletAddress, FRUIT_ID),
          seedReadMulticallContract.balanceOf(smartWalletAddress, GLOWY_BAG_ID),
        ];

        const multicallResp = await Promise.all(multicallCalls);

        const [
          ewSeed,
          ewHair,
          ewFren,
          ewFruit,
          ewGlowyBag,
          smSeed,
          smHair,
          smFren,
          smFruit,
          smGlowyBag,
        ] = multicallResp;

        setExternalWalletBalances({
          seed: ewSeed.toString(),
          fruit: ewFruit.toString(),
          fren: ewFren.toString(),
          hair: ewHair.toString(),
          glowyBag: ewGlowyBag.toString(),
        });

        setSmartWalletBalances({
          seed: smSeed.toString(),
          fruit: smFruit.toString(),
          fren: smFren.toString(),
          hair: smHair.toString(),
          glowyBag: smGlowyBag.toString(),
        });
      } catch (e) {
        console.log("Error in getBalances: ", e);
      }
    },
    [smartWalletAddress],
  );

  const handleExternalWalletToSmartWalletTransfer = async () => {
    try {
      if (externalWalletProvider && externalWalletAddress) {
        setIsTransferring(true);
        const provider = new ethers.providers.Web3Provider(
          externalWalletProvider,
        );
        const signer = provider.getSigner();
        const seedContract = new Contract(
          SEED_CONTRACT_ADDRESS,
          seedAbi,
          signer,
        ) as Seed;
        const fromAddress = externalWalletAddress;
        const toAddress = smartWalletAddress;
        const ids = [SEED_ID, HAIR_ID, FREN_ID, FRUIT_ID, GLOWY_BAG_ID];
        const amounts = [
          externalWalletBalances.seed,
          externalWalletBalances.hair,
          externalWalletBalances.fren,
          externalWalletBalances.fruit,
          externalWalletBalances.glowyBag,
        ];
        const data = "0x";

        const txn = await seedContract.safeBatchTransferFrom(
          fromAddress,
          toAddress,
          ids,
          amounts,
          data,
        );
        await txn.wait();
        setIsTransferring(false);
        toast("Success", {
          type: "success",
        });

        await getBalances(externalWalletAddress);
      }
    } catch (e) {
      setIsTransferring(false);
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleExternalWalletToSmartWalletTransfer: ", e);
    }
  };

  const handleSmartWalletToExternalWalletTransfer = async () => {
    try {
      if (externalWalletAddress) {
        setIsTransferring(true);
        const fromAddress = smartWalletAddress;
        const toAddress = externalWalletAddress;
        const ids = [SEED_ID, HAIR_ID, FREN_ID, FRUIT_ID, GLOWY_BAG_ID];
        const amounts = [
          smartWalletBalances.seed,
          smartWalletBalances.hair,
          smartWalletBalances.fren,
          smartWalletBalances.fruit,
          smartWalletBalances.glowyBag,
        ];
        const data = "0x";

        await mutateAsyncSeedSafeBatchTransfer({
          args: [fromAddress, toAddress, ids, amounts, data],
        });

        setIsTransferring(false);
        toast("Success", {
          type: "success",
        });
        await getBalances(externalWalletAddress);
      }
    } catch (e) {
      setIsTransferring(false);
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleSmartWalletToExternalWalletTransfer: ", e);
    }
  };

  useEffect(() => {
    if (externalWalletAddress && externalWalletProvider) {
      getBalances(externalWalletAddress);
    }
  }, [externalWalletAddress, getBalances, externalWalletProvider]);

  return (
    <div>
      <CenterDiv>
        <ConnectButtonContainer>
          <w3m-button size="md" label="Connect your main wallet" />
        </ConnectButtonContainer>
      </CenterDiv>

      <SectionHeading>Balances of External Wallet</SectionHeading>
      <CenterDiv>
        <BalanceText>Seeds: {externalWalletBalances.seed}</BalanceText>
        <BalanceText>Fruits: {externalWalletBalances.fruit}</BalanceText>
        <BalanceText>Frens: {externalWalletBalances.fren}</BalanceText>
        <BalanceText>Hairs: {externalWalletBalances.hair}</BalanceText>
        <BalanceText>Glowy Bags: {externalWalletBalances.glowyBag}</BalanceText>
      </CenterDiv>
      <CenterDiv>
        <ClaimButton
          isLoading={isTransferring}
          onClick={handleExternalWalletToSmartWalletTransfer}
          disabled={isTransferring || !isConnected}
          style={{ width: "100%" }}
        >
          <ClaimButtonText>
            {isTransferring ? "Transferring..." : "Transfer to Smart Wallet"}
          </ClaimButtonText>
        </ClaimButton>
      </CenterDiv>

      <SectionHeading>Balances of Smart Wallet</SectionHeading>
      <CenterDiv>
        <BalanceText>Seeds: {smartWalletBalances.seed}</BalanceText>
        <BalanceText>Fruits: {smartWalletBalances.fruit}</BalanceText>
        <BalanceText>Frens: {smartWalletBalances.fren}</BalanceText>
        <BalanceText>Hairs: {smartWalletBalances.hair}</BalanceText>
        <BalanceText>Glowy Bags: {smartWalletBalances.glowyBag}</BalanceText>
      </CenterDiv>
      <CenterDiv>
        <ClaimButton
          isLoading={isTransferring}
          onClick={handleSmartWalletToExternalWalletTransfer}
          disabled={isTransferring || !isConnected}
          style={{ width: "100%" }}
        >
          <ClaimButtonText>
            {isTransferring ? "Transferring..." : "Transfer to External Wallet"}
          </ClaimButtonText>
        </ClaimButton>
      </CenterDiv>
    </div>
  );
};
