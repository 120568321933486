import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import partnerBgImg from "../../images/partner/partnerBgImg.jpg";

const BackButton = styled(Link)`
  padding: 10px 20px;
  background: #90a9aa;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background: #7b9293;
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  transition: all 0.2s ease;
`;

const SimpleMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
`;

const WelcomeContainer = styled.div`
  max-width: 600px;
  margin: 10px auto;
  padding: 20px;
  color: white;
  background-color: rgba(45, 36, 45, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  line-height: 1.6;

  @media (max-width: 768px) {
    margin: 10px 20px;
    padding: 15px;
    font-size: 14px;
    
    h2 {
      font-size: 20px;
    }
  }
`;

const ThankYouContainer = styled(WelcomeContainer)`
  text-align: center;
`;

const LoadingSpinner = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  color: white;
  background-color: rgba(45, 36, 45, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  font-size: 18px;

  @media (max-width: 768px) {
    margin: 20px;
    padding: 15px;
    font-size: 16px;
  }
`;

const FormContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(45, 36, 45, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  
  h1 {
    color: white;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.label`
  font-weight: 500;
  margin-bottom: 5px;
  color: white;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const TextArea = styled.textarea`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  min-height: 100px;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  
  option {
    color: black;
    background-color: white;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background: #90a9aa;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  
  &:hover {
    background: #7b9293;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const TopSubmitButton = styled(SubmitButton)`
  margin: 0 auto 20px auto;
  display: block;
  font-size: 18px;
  padding: 12px 30px;
  
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 25px;
  }
`;

const PartnerBackground = styled.div`
  background-image: url(${partnerBgImg});
  min-height: 100vh;
  width: 100%;
  background-color: #2d242d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0;
`;

interface FormData {
  projectName: string;
  twitter: string;
  contractAddress: string;
  description: string;
  integrationType: string;
  contactHandle: string;
}

export function PartnerSubmission({ isMobile }: { isMobile: boolean }) {
  const [formData, setFormData] = useState<FormData>({
    projectName: "",
    twitter: "",
    contractAddress: "",
    description: "",
    integrationType: "",
    contactHandle: ""
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      await addDoc(collection(db, "partner-submissions"), {
        ...formData,
        timestamp: new Date(),
        status: 'new'
      });
      
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <main className="main">
      <PartnerBackground>
        <SimpleMenu>
          <BackButton to="/">← Back to Map</BackButton>
        </SimpleMenu>
        
        {isLoading ? (
          <LoadingSpinner>
            <h2>Submitting your application...</h2>
            <p>Please wait while we process your submission.</p>
          </LoadingSpinner>
        ) : isSubmitted ? (
          <ThankYouContainer>
            <h2>Thank you for the submission!</h2>
            <p>We will do our best to reach out in the next 24-48 hours to discuss details of the integration!</p>
          </ThankYouContainer>
        ) : (
          <>
            <WelcomeContainer>
              <h2>Dear Traveller,</h2>
              <p>Are you a project founder or part of a community you would like to see in Arkadia Park?</p>
              <p>If so make sure to complete this 30 second form!</p>
              <p>At the moment we only work with projects that have a smart contract deployed on the Polygon ecosystems or on Polygon testnets. Good luck!</p>
            </WelcomeContainer>

            <FormContainer>
              <h1>Partner Integration Form</h1>
              <SubmitButtonContainer>
                <SubmitButton type="submit" form="partnerForm">Submit Application</SubmitButton>
              </SubmitButtonContainer>
              <Form id="partnerForm" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Project Name*</Label>
                  <Input
                    type="text"
                    name="projectName"
                    value={formData.projectName}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label>Official Twitter*</Label>
                  <Input
                    type="url"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label>Smart Contract Address*</Label>
                  <Input
                    type="text"
                    name="contractAddress"
                    value={formData.contractAddress}
                    onChange={handleChange}
                    pattern="0x[a-fA-F0-9]{40}"
                    required
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label>Project Description*</Label>
                  <TextArea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label>Integration Type*</Label>
                  <Select
                    name="integrationType"
                    value={formData.integrationType}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="token">Token</option>
                    <option value="nft">NFT</option>
                    <option value="protocol">Protocol</option>
                    <option value="other">Other</option>
                  </Select>
                </FormGroup>
                
                <FormGroup>
                  <Label>Telegram/Discord Handle</Label>
                  <Input
                    type="text"
                    name="contactHandle"
                    value={formData.contactHandle}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Form>
            </FormContainer>
          </>
        )}
      </PartnerBackground>
    </main>
  );
}
