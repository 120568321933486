import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../state";
import { setDashboardValuesAsync } from "../../../state/dashboard";
import {
  MAX_TREE_LIFETIME_WATER_COUNT,
  MIN_WATER_DURATION,
  POLLING_TIME,
  PRICE_PER_TOKEN_SEED,
  SEED_ADDRESS,
  SEED_COMPLETION_COUNT,
  SEED_GARDEN_CONTRACT_ADDRESS,
  TREE_COMPLETION_COUNT,
  TREE_GARDEN_CONTRACT_ADDRESS,
} from "../../../config/purple-config";
import { setSeedsAndTreesAsync } from "../../../state/nfts";
import { SixBoxes } from "./SixBoxes";
import Modal from "react-modal";
import millify from "millify";
import BigNumber from "bignumber.js";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import seedGardenAbi from "../../../config/abi/seedGarden.json";
import treeGardenAbi from "../../../config/abi/treeGarden.json";
import seedAbi from "../../../config/abi/seed.json";
import { ClaimButton, ClaimButtonText } from "./styles";
import { toast } from "react-toastify";
import { TransferAssets } from "../../../components/TransferAssets";

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 820px;
  gap: 75px;
  margin: 30px 0;
`;
const ValuesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Values = styled.div`
  color: white;
  font-size: 24px;
  background: purple;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 5px;

  @media (max-width: 1023px) {
    font-size: 18px;
    border-radius: 9px;
  }
`;

const CardsViewContainer = styled.div<{ isfirstmintsuccess?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  opacity: ${(props) => (props.isfirstmintsuccess ? 1 : 0.5)};
  cursor: ${(props) => (props.isfirstmintsuccess ? "default" : "not-allowed")};
`;

export const CardsView = ({
  isFirstMintSuccess,
  doesUserHaveSeeds,
}: {
  isFirstMintSuccess: boolean;
  doesUserHaveSeeds: boolean;
}) => {
  const { allSeeds, seedsCount, allTrees, treesCount } = useAppSelector(
    (state) => state.nfts.data,
  );

  const allFilteredSeeds = allSeeds;
  // let seedClaimedCount = 0;
  const claimedSeedsIds: string[] = [];
  let seedExpiredCount = 0;
  const expiredSeedsIds: string[] = [];
  for (let i = 0; i < allFilteredSeeds.length; i++) {
    const daysPassed =
      (Date.now() - parseFloat(allFilteredSeeds[i].lastWatered) * 1000) /
      (MIN_WATER_DURATION * 1000);

    if (allFilteredSeeds[i].status === "4") {
      claimedSeedsIds.push(allFilteredSeeds[i].id);
      // seedClaimedCount++;
    } else if (
      Date.now() >
        (parseInt(allFilteredSeeds[i].nextWaterTime) + MIN_WATER_DURATION) *
          1000 &&
      parseFloat(allFilteredSeeds[i].wateredCount) !== SEED_COMPLETION_COUNT &&
      allFilteredSeeds[i].status !== "3" &&
      (allFilteredSeeds[i].skipsAllowed === "0" ||
        daysPassed >= parseFloat(allFilteredSeeds[i].skipsAllowed))
    ) {
      expiredSeedsIds.push(allFilteredSeeds[i].id);
      seedExpiredCount++;
    }
  }

  const nonClaimedSeeds = allFilteredSeeds.filter(
    (seed) => !claimedSeedsIds.includes(seed.id),
  );
  const nonClaimedNonExpiredSeeds = nonClaimedSeeds.filter(
    (seed) => !expiredSeedsIds.includes(seed.id),
  );

  const allFilteredTrees = allTrees;
  // let treeClaimedCount = 0;
  const claimedTreesIds: string[] = [];
  let treeExpiredCount = 0;
  const expiredTreesIds: string[] = [];
  for (let i = 0; i < allFilteredTrees.length; i++) {
    const daysPassed =
      (Date.now() - parseFloat(allFilteredTrees[i].lastWatered) * 1000) /
      (MIN_WATER_DURATION * 1000);

    if (allFilteredTrees[i].status === "4") {
      claimedTreesIds.push(allFilteredTrees[i].id);
      // treeClaimedCount++;
    } else if (
      Date.now() >
        (parseInt(allFilteredTrees[i].nextWaterTime) + MIN_WATER_DURATION) *
          1000 &&
      parseFloat(allFilteredTrees[i].wateredCount) !== TREE_COMPLETION_COUNT &&
      parseFloat(allFilteredTrees[i].lifetimeWateredCount!) !==
        MAX_TREE_LIFETIME_WATER_COUNT &&
      allFilteredTrees[i].status !== "3" &&
      (allFilteredTrees[i].skipsAllowed === "0" ||
        daysPassed >= parseFloat(allFilteredTrees[i].skipsAllowed))
    ) {
      expiredTreesIds.push(allFilteredTrees[i].id);
      treeExpiredCount++;
    }
  }

  const nonClaimedTrees = allFilteredTrees.filter(
    (tree) => !claimedTreesIds.includes(tree.id),
  );
  const nonClaimedNonExpiredTrees = nonClaimedTrees.filter(
    (seed) => !expiredTreesIds.includes(seed.id),
  );

  return (
    <CardsViewContainer
      isfirstmintsuccess={isFirstMintSuccess || doesUserHaveSeeds}
    >
      <ValuesContainer>
        <Values>Trees Planted: {treesCount}</Values>
        <Values>Trees Expired: {treeExpiredCount}</Values>
      </ValuesContainer>

      <ValuesContainer>
        <Values>Seeds Planted: {seedsCount}</Values>
        <Values>Seeds Expired: {seedExpiredCount}</Values>
      </ValuesContainer>

      <CardsContainer>
        <SixBoxes
          activeSeeds={nonClaimedNonExpiredSeeds}
          activeTrees={nonClaimedNonExpiredTrees}
        />
      </CardsContainer>
    </CardsViewContainer>
  );
};

const MainContainer = styled.div`
  padding-top: 70px;
  min-height: 100vh;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 80px;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
    row-gap: 50px;
  }
`;
const LearnMoreContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  row-gap: 10px;

  @media (max-width: 1400px) {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const WaterAllContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1400px) {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const LearnMoreButton = styled.button`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #679a7e;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 30px;
  margin-bottom: 20px;

  // button text
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 10.263px; /* 34.21% */
  letter-spacing: 0.088px;
  -webkit-text-stroke: 1px black; /* For webkit browsers */

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    margin-bottom: 50px;
  }
`;
const WaterAllButtonContainer = styled.div<{ $nofren?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  opacity: ${(props) => (props.$nofren ? 0.7 : 1)};
  cursor: ${(props) => (props.$nofren ? "not-allowed" : "pointer")};

  &:hover {
    cursor: ${(props) => (props.$nofren ? "not-allowed" : "pointer")};
  }
`;
const WaterAllButton = styled.button`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #679a7e;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 40px;

  // button text
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 10.263px; /* 34.21% */
  letter-spacing: 0.088px;
  -webkit-text-stroke: 1px black; /* For webkit browsers */
  z-index: 99;
`;
const WaterAllSeedsButton = styled.button`
  z-index: 98;
  margin-top: -5px;
  width: 70%;
  border: 1px solid #2d242d;
  background: #93c0a7;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  // button text
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 10.263px; /* 34.21% */
  letter-spacing: 0.088px;
  -webkit-text-stroke: 1px black; /* For webkit browsers */
  cursor: pointer;
  transition: opacity 1s ease-out;
`;
const WaterAllTreesButton = styled.button`
  z-index: 98;
  width: 70%;
  border-radius: 0 0 10px 10px;
  border: 1px solid #2d242d;
  border-top: none;
  background: #93c0a7;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  // button text
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 10.263px; /* 34.21% */
  letter-spacing: 0.088px;
  -webkit-text-stroke: 1px black; /* For webkit browsers */
  cursor: pointer;
  transition: opacity 1s ease-out;
`;
const LoadingHeading = styled.h1`
  font-size: 54px;
  color: white;
`;

const ModalInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 950px;

  border-radius: 15px;
  border: 1px solid #000;
  background: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;
`;
const MintModalInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;

  border-radius: 15px;
  border: 1px solid #000;
  background: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;

  @media (max-width: 1023px) {
    border-radius: 8px;
  }
`;
const MintInput = styled.input`
  padding: 15px 40px;
  border-radius: 12px;
  width: 100%;
  font-size: 24px;

  @media (max-width: 1023px) {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 6px;
  }
`;
const MintInputBox = styled.div`
  width: 100%;
`;
const MintModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
`;
const ContentInnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeadingBox = styled.div`
  border-radius: 15px;
  border: 1px solid #000;
  background-color: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px 60px;
  width: 100%;
  max-width: 475px;
  z-index: 89;

  @media (max-width: 1023px) {
    font-size: 20px;
    padding: 5px 10px;
  }
`;
const BalanceHeading = styled.div`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;
const BalanceSubHeading = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  -webkit-text-stroke: 0.4px black;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;
const ValueBox = styled.div`
  border-radius: 31px;
  border: 1px solid #000;
  background-color: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 410px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 10px;
  flex-wrap: wrap;
  margin-top: -10px;
  -webkit-text-stroke: 0.7px black;
  z-index: 88;
  row-gap: 10px;
  column-gap: 10px;

  @media (max-width: 1023px) {
    padding: 30px 10px 20px 10px;
  }
`;
const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const BoxHeading = styled.div`
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;
const BoxValue = styled.div`
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border: 0.7px solid #000;
  background-color: #9eafff;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);

  color: #fff;
  text-align: center;
  font-size: 23px;
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;

  @media (max-width: 1023px) {
    min-width: 50px;
    min-height: 50px;
    font-size: 16px;
    margin-top: 3px;
  }
`;
const ReturnButton = styled.button`
  background-color: #6d83ed;
  padding: 15px 20px;
  border-radius: 12px;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    padding: 5px 10px;
  }
`;
const ButtonText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;

const MintHeading = styled.h2`
  color: white;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-text-stroke: 1px black; /* For webkit browsers */
  background-color: purple;
  padding: 5px 15px;
  border-radius: 12px;
`;

export const Home = ({
  account,
  balance,
}: {
  account: string;
  balance: string;
}) => {
  const {
    totalSupplyOfSeeds,
    totalSupplyOfFrens,
    totalSupplyOfFruits,
    totalSupplyOfAncientTrees,
    userData,
  } = useAppSelector((state) => state.dashboard.data);

  const [showWaterDropdown, setShowWaterDropdown] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [seedsToMint, setSeedsToMint] = useState(1);

  const [openWeb3Modal, setOpenWeb3Modal] = useState(false);

  const [open, setOpen] = useState(false);
  const [mintOpen, setMintOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [firstMintSuccessful, setFirstMintSuccessful] = useState(false);

  const nativeTokenValue = new BigNumber(seedsToMint)
    .multipliedBy(PRICE_PER_TOKEN_SEED)
    .multipliedBy(new BigNumber(10).pow(18))
    .toString();

  const showWater = async () => {
    if (!new BigNumber(userData.balanceOfFrens).isGreaterThan(0)) return;
    setShowWaterDropdown(() => !showWaterDropdown);
  };

  const { contract: seedGardenContract } = useContract(
    SEED_GARDEN_CONTRACT_ADDRESS,
    seedGardenAbi,
  );
  const { contract: treeGardenContract } = useContract(
    TREE_GARDEN_CONTRACT_ADDRESS,
    treeGardenAbi,
  );

  const { contract: seedContract } = useContract(SEED_ADDRESS, seedAbi);

  const { mutateAsync: mutateAsyncWaterAllSeeds } = useContractWrite(
    seedGardenContract,
    "waterAll",
  );
  const { mutateAsync: mutateAsyncWaterAllTrees } = useContractWrite(
    treeGardenContract,
    "waterAll",
  );
  const { mutateAsync: mutateAsyncMintSeeds, isLoading: isMinting } =
    useContractWrite(seedContract, "claim");

  const { mutateAsync: mutateAsyncSetApprovalForAll, isLoading: isApproving } =
    useContractWrite(seedContract, "setApprovalForAll");

  const handleSeedApprove = async () => {
    try {
      await mutateAsyncSetApprovalForAll({
        args: [SEED_GARDEN_CONTRACT_ADDRESS, true],
      });
      toast("Success", {
        type: "success",
      });
      if (account && !isApproving) {
        dispatch(setDashboardValuesAsync(account));
      }
    } catch (err) {
      console.log("Error while approving the seed contract: ", err);
      toast("Error", {
        type: "error",
      });
    }
  };

  const handleMint = async () => {
    try {
      const receiver = account;
      const tokenId = 0;
      const quantity = seedsToMint;
      const currency = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
      const pricePerTokenInWei = new BigNumber(PRICE_PER_TOKEN_SEED)
        .multipliedBy(new BigNumber(10).pow(18))
        .toString();
      const allowListProof = {
        proof: [
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
        quantityLimitPerWallet:
          "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        pricePerToken: pricePerTokenInWei,
        currency: currency,
      };
      const data = "0x";

      await mutateAsyncMintSeeds({
        args: [
          receiver,
          tokenId,
          quantity,
          currency,
          pricePerTokenInWei,
          allowListProof,
          data,
        ],
        overrides: {
          value: nativeTokenValue,
        },
      });

      localStorage.setItem("successfulFirstMint", "true");

      toast("Success", {
        type: "success",
      });

      if (!isMinting) {
        setFirstMintSuccessful(true);
        setMintOpen(false);
        dispatch(setDashboardValuesAsync(account));
      }
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleMint: ", e);
    }
  };

  const handleWaterAll = async (nftType: "seeds" | "trees") => {
    try {
      if (nftType === "seeds") {
        await mutateAsyncWaterAllSeeds({ args: [] });
      } else {
        await mutateAsyncWaterAllTrees({ args: [] });
      }
      toast("Success", {
        type: "success",
      });
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleWaterAll: ", e);
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const setFirstSuccessfulMint = () => {
      const isSuccessful = localStorage.getItem("successfulFirstMint");
      if (isSuccessful === "true") {
        setFirstMintSuccessful(true);
      }
    };

    setFirstSuccessfulMint();
  }, []);

  useEffect(() => {
    dispatch(setDashboardValuesAsync(account)).catch((e) =>
      console.log("Error while dispatching setDashboardValuesAsync: ", e),
    );
  }, [dispatch, account]);

  useEffect(() => {
    setIsLoading(() => true);
    dispatch(setSeedsAndTreesAsync(account))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));

    const init = setInterval(() => {
      dispatch(setSeedsAndTreesAsync(account)).catch((e) =>
        console.log("Error while dispatching setSeedsAndTreesAsync: ", e),
      );
    }, POLLING_TIME);

    return () => clearInterval(init);
  }, [dispatch, account]);

  return (
    <MainContainer>
      <StyledContainer>
        <LearnMoreContainer>
          <a
            href="https://discord.gg/SzPUwZsVWe"
            target="_blank"
            rel="noreferrer"
          >
            <LearnMoreButton>Learn More</LearnMoreButton>
          </a>{" "}
        </LearnMoreContainer>

        {isLoading ? (
          <LoadingHeading>Loading...</LoadingHeading>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxWidth: "900px",
              }}
            >
              {userData.isSeedApproved ? (
                <>
                  {!firstMintSuccessful && (
                    <MintHeading>Please mint your first seed</MintHeading>
                  )}
                  <CardsView
                    isFirstMintSuccess={firstMintSuccessful}
                    doesUserHaveSeeds={new BigNumber(
                      userData.balanceOfSeeds,
                    ).isGreaterThan(0)}
                  />
                </>
              ) : (
                <LearnMoreButton onClick={handleSeedApprove}>
                  {isApproving ? "Approving..." : "Approve Seeds"}
                </LearnMoreButton>
              )}
            </div>
          </div>
        )}
        <WaterAllContainer>
          {/* <LearnMoreButton onClick={() => setMintOpen(true)}>
            Mint Seeds
          </LearnMoreButton> */}
          <WaterAllButtonContainer
            $nofren={!new BigNumber(userData.balanceOfFrens).isGreaterThan(0)}
          >
            <WaterAllButton onClick={showWater}>Water All</WaterAllButton>
            <WaterAllSeedsButton
              style={{ display: `${showWaterDropdown ? "flex" : "none"}` }}
              onClick={() => handleWaterAll("seeds")}
            >
              Seeds
            </WaterAllSeedsButton>
            <WaterAllTreesButton
              style={{ display: `${showWaterDropdown ? "flex" : "none"}` }}
              onClick={() => handleWaterAll("trees")}
            >
              Trees
            </WaterAllTreesButton>
          </WaterAllButtonContainer>
          {/* <LearnMoreButton onClick={handleOpen}>Balances</LearnMoreButton> */}
          <LearnMoreButton onClick={() => setOpenWeb3Modal(true)}>
            Transfer Assets
          </LearnMoreButton>
        </WaterAllContainer>

        <Modal
          isOpen={openWeb3Modal}
          onRequestClose={() => setOpenWeb3Modal(false)}
          aria-labelledby="Show balances"
          aria-describedby="Show the balances of user & the contracts"
        >
          <MintModalInnerContainer>
            <MintModalContent>
              <BalanceHeading>Transfer Assets</BalanceHeading>
              <TransferAssets smartWalletAddress={account} />
              <ClaimButton onClick={() => setOpenWeb3Modal(false)}>
                <ClaimButtonText>Close</ClaimButtonText>
              </ClaimButton>
            </MintModalContent>
          </MintModalInnerContainer>
        </Modal>

        <Modal
          isOpen={mintOpen}
          onRequestClose={() => setMintOpen(false)}
          aria-labelledby="Show balances"
          aria-describedby="Show the balances of user & the contracts"
        >
          <MintModalInnerContainer>
            <MintModalContent>
              <BalanceHeading>Mint Seeds</BalanceHeading>
              <MintInputBox>
                <MintInput
                  min={1}
                  step={1}
                  type="number"
                  placeholder={"Tokens to mint"}
                  value={seedsToMint}
                  onChange={(e) => setSeedsToMint(Number(e.target.value))}
                />
              </MintInputBox>
              {new BigNumber(balance).isLessThan(nativeTokenValue) && (
                <>
                  <BalanceSubHeading>
                    This account does not have enough MATIC balance. Please
                    top-up your smart wallet to fund the mint txn.
                  </BalanceSubHeading>

                  <BalanceSubHeading>
                    You need at least{" "}
                    {seedsToMint * Number(PRICE_PER_TOKEN_SEED)} MATIC
                  </BalanceSubHeading>
                </>
              )}
            </MintModalContent>
            <ModalFooter>
              <ClaimButton
                isLoading={isMinting}
                onClick={handleMint}
                disabled={
                  seedsToMint < 1 ||
                  new BigNumber(balance).isLessThan(nativeTokenValue)
                }
              >
                <ClaimButtonText>
                  {isMinting ? "Minting..." : "Mint"}
                </ClaimButtonText>
              </ClaimButton>

              <ClaimButton onClick={() => setMintOpen(false)}>
                <ClaimButtonText>Close</ClaimButtonText>
              </ClaimButton>
            </ModalFooter>
          </MintModalInnerContainer>
        </Modal>

        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Show balances"
          aria-describedby="Show the balances of user & the contracts"
        >
          <ModalInnerContainer>
            <ModalContent>
              <ContentInnerBox>
                <HeadingBox>
                  <BalanceHeading>Your Balance</BalanceHeading>
                </HeadingBox>
                <ValueBox>
                  <Box>
                    <BoxHeading>Seed</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(userData.balanceOfSeeds))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Fren</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(userData.balanceOfFrens))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Fruit</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(userData.balanceOfFruits))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Ancient Tree</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(userData.balanceOfAncientTrees))}
                    </BoxValue>
                  </Box>
                </ValueBox>
              </ContentInnerBox>
              <ContentInnerBox>
                <HeadingBox>
                  <BalanceHeading>Total Supply</BalanceHeading>
                </HeadingBox>
                <ValueBox>
                  <Box>
                    <BoxHeading>Seed</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(totalSupplyOfSeeds))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Fren</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(totalSupplyOfFrens))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Fruit</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(totalSupplyOfFruits))}
                    </BoxValue>
                  </Box>
                  <Box>
                    <BoxHeading>Ancient Tree</BoxHeading>
                    <BoxValue>
                      {millify(parseFloat(totalSupplyOfAncientTrees))}
                    </BoxValue>
                  </Box>
                </ValueBox>
              </ContentInnerBox>
            </ModalContent>
            <ModalFooter>
              <ReturnButton onClick={handleClose}>
                <ButtonText>Close</ButtonText>
              </ReturnButton>
            </ModalFooter>
          </ModalInnerContainer>
        </Modal>
      </StyledContainer>
    </MainContainer>
  );
};
