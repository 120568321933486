import dashboardReducers from "./dashboard/index";
import nftsReducers from "./nfts/index";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  // TODO: Remove this before deploying to prod
  devTools: true,
  reducer: {
    dashboard: dashboardReducers,
    nfts: nftsReducers,
  },
});

type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
