import BgMusic from "./bgMusic";
import DialogViews from "./dialogViews";
import s1 from "../../images/map/s1.png";
import s2 from "../../images/map/s2.png";
import s3 from "../../images/map/s3.png";
import s4 from "../../images/map/twittwerX.png";
import s5 from "../../images/map/discord.png";
import s17 from "../../images/map/s17.png";

const UiControls = () => {
  return (
    <div className="ui-controls">
      <BgMusic />
      <DialogViews />
      <div className="ui-controls__left">
        {/* <a
          href="https://www.arkadiapark.com/"
          target="blank"
          className="btn-square text-white"
        >
          Learn More
        </a> */}
        <a
          target="blank"
          href="https://x.com/arkadia_park"
          className="btn-round"
          title="x"
        >

          <img src={s4} alt="" style={{ width: 18 }} />
        </a>
        <a
          target="blank"
          href="https://discord.gg/KKDYaGRz"
          className="btn-round"
          title="Discord"
        >
          <img src={s5} alt="" />
        </a>
        <a
          target="blank"
          href="https://www.youtube.com/@Arkadia_Park_Web3"
          className="btn-round"
          title="Youtube"
        >
          <img src={s17} alt="" />
        </a>


      </div>
      <div className="ui-controls__right">
        <a
          target="blank"
          href="https://opensea.io/collection/arkadia-edibles"
          className="btn-round"
          title="Opensea"
        >
          <img src={s1} alt="" />
        </a>
        <a
          target="blank"
          href="https://magiceden.io/collections/polygon/arkadians"
          className="btn-round"
          title="Magic Eden"
        >
          <img src={s2} alt="" />
        </a>
      </div>
    </div>
  );
};

export default UiControls;
