export const SEED_COMPLETION_COUNT = 4;
export const TREE_COMPLETION_COUNT = 4;
export const SEED_GARDEN_CONTRACT_ADDRESS =
  "0xab3150302b387b2C064f219ec01347be8340F1A5";
export const TREE_GARDEN_CONTRACT_ADDRESS =
  "0xf70D8777cB999A7c2A14433804d192BdF702E5CD";
export const SEED_ADDRESS = "0x1485716aD326fb9316Bff95f3fd53978E9D63Bf9";
export const TREE_ADDRESS = "0x9D15dB89026eCD2E422479399816f462B8a053b9";
export const TREE_ID = 0;
export const ANCIENT_TREE_ID = 1;
export const MAX_TREE_LIFETIME_WATER_COUNT = 7;
export const MIN_WATER_DURATION = 60;
export const PRICE_PER_TOKEN_SEED = "0.001";

export const POLLING_TIME = 30_000;
