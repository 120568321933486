import { useState } from "react";
import {
  CardContainer,
  Card,
  CardImageBox,
  CardTop,
  CardBottom,
  CardImage,
  NFTName,
  StyledModalBox,
  ClaimButton,
  CongratsHeading,
  ClaimButtonText,
  CongratsSubContainer,
  CongratsSubText,
  StyledFruitImage,
  TwoButtonBox,
} from "./styles";
import SeedImage from "../../../images/purpleForest/nfts/seed.svg";
import TreeImage from "../../../images/purpleForest/nfts/tree.svg";
import Countdown from "react-countdown";
import Modal from "react-modal";
import {
  MIN_WATER_DURATION,
  SEED_COMPLETION_COUNT,
  SEED_GARDEN_CONTRACT_ADDRESS,
} from "../../../config/purple-config";
import { ISeedOrTreeInfo, setSeedsAndTreesAsync } from "../../../state/nfts";
import { useAppDispatch } from "../../../state";
import { Tooltip } from "react-tooltip";
import { useAddress, useContract, useContractWrite } from "@thirdweb-dev/react";
import seedGardenAbi from "../../../config/abi/seedGarden.json";
import { SeedGarden__factory } from "../../../config/abi/types";
import { JSON_RPC_PROVIDER } from "../../../config/config";
import { toast } from "react-toastify";

export const SeedCard = ({ seed }: { seed: ISeedOrTreeInfo }) => {
  const account = useAddress();

  const { contract: seedGardenContract } = useContract(
    SEED_GARDEN_CONTRACT_ADDRESS,
    seedGardenAbi,
  );

  const { mutateAsync: mutateAsyncClaimTree, isLoading: isClaiming } =
    useContractWrite(seedGardenContract, "claimTree");
  const { mutateAsync: mutateAsyncWaterPlants, isLoading: isWatering } =
    useContractWrite(seedGardenContract, "waterPlants");

  const [open, setOpen] = useState(false);
  const [showLotterySuccessModal, setShowLotterySuccessModal] = useState(false);

  const dispatch = useAppDispatch();

  let status: string;
  if (seed.status === "0") status = "NotFound";
  else if (seed.status === "1") status = "Planted";
  else if (seed.status === "2") status = "Expired";
  else if (seed.status === "3") status = "Claimable";
  else status = "Claimed";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClaimRewardClick = async () => {
    try {
      await mutateAsyncClaimTree({ args: [seed.id] });
      toast("Success", {
        type: "success",
      });
      if (account && !isClaiming) {
        setOpen(false);
        setShowLotterySuccessModal(false);
        dispatch(setSeedsAndTreesAsync(account));
      }
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleClaimRewardClick: ", e);
    }
  };

  const handleWaterClick = async () => {
    try {
      await mutateAsyncWaterPlants({ args: [seed.id] });
      toast("Success", {
        type: "success",
      });
      if (account && !isWatering) {
        const seedGardenContract = SeedGarden__factory.connect(
          SEED_GARDEN_CONTRACT_ADDRESS,
          JSON_RPC_PROVIDER,
        );
        const status = await seedGardenContract.getPlantStatus(seed.id);
        if (status.toString() === "3") {
          setShowLotterySuccessModal(() => true);
        }
        dispatch(setSeedsAndTreesAsync(account));
      }
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleWaterClick: ", e);
    }
  };

  if (status === "Claimed") {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the seed"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the seed rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the rewards right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Card>
          <CardImageBox>
            <CardImage src={SeedImage} alt="Seed NFT" />
          </CardImageBox>
        </Card>
        <CardBottom>
          <NFTName>Claimed</NFTName>
        </CardBottom>
      </CardContainer>
    );
  }

  if (
    status === "Claimable" ||
    seed.wateredCount >= SEED_COMPLETION_COUNT.toString()
  ) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the seed"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the seed rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the rewards right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Card>
          <CardImageBox>
            <CardImage src={SeedImage} alt="Seed NFT" />
          </CardImageBox>
        </Card>
        <CardBottom $isloading={isClaiming} onClick={handleOpen}>
          <NFTName>{isClaiming ? "Loading..." : "Claim"}</NFTName>
        </CardBottom>
      </CardContainer>
    );
  }

  if (Date.now() < parseInt(seed.nextWaterTime) * 1000) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the seed"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the seed rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the rewards right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <CardTop style={{ background: "#679a7e" }}>
          <NFTName>Next water</NFTName>
          <NFTName>
            <>
              <Countdown
                key={seed.lastWatered}
                date={parseInt(seed.nextWaterTime) * 1000}
                onComplete={() => dispatch(setSeedsAndTreesAsync(account!))}
              >
                <div>Water now</div>
              </Countdown>
            </>
          </NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={SeedImage} alt="Seed NFT" />
          </CardImageBox>
        </Card>
      </CardContainer>
    );
  } else if (
    Date.now() > parseInt(seed.nextWaterTime) * 1000 &&
    Date.now() < (parseInt(seed.nextWaterTime) + MIN_WATER_DURATION) * 1000
  ) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the seed"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the seed rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the rewards right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <CardTop style={{ backgroundColor: "#cc4747" }}>
          <NFTName>Expires in</NFTName>
          <NFTName>
            <>
              <Countdown
                key={seed.nextWaterTime}
                date={
                  (parseInt(seed.nextWaterTime) + MIN_WATER_DURATION) * 1000
                }
                onComplete={() => dispatch(setSeedsAndTreesAsync(account!))}
              >
                <div>Expired</div>
              </Countdown>
            </>
          </NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={SeedImage} alt="Seed NFT" />
          </CardImageBox>
        </Card>
        <CardBottom $isloading={isWatering} onClick={handleWaterClick}>
          <NFTName>{isWatering ? "Loading..." : "Water"}</NFTName>
        </CardBottom>
      </CardContainer>
    );
  } else {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the seed"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the seed rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the rewards right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={TreeImage} alt="Tree reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {seed.amount}x purple trees
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>
        <CardTop style={{ backgroundColor: "#cc4747" }}>
          <NFTName>Expired</NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={SeedImage} alt="Seed NFT" />
          </CardImageBox>
        </Card>
        <CardBottom
          $isloading={isWatering}
          onClick={handleWaterClick}
          data-tooltip-id="replant-expired-nft"
          data-tooltip-content={`You can still water because you have ${seed.skipsAllowed} skips allowed`}
          data-tooltip-place="bottom"
        >
          <NFTName>{isWatering ? "Loading..." : "Water"}</NFTName>
          <Tooltip id="replant-expired-nft" />
        </CardBottom>
      </CardContainer>
    );
  }
};
