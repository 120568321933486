import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  coinbaseWallet,
  embeddedWallet,
  metamaskWallet,
  smartWallet,
  ThirdwebProvider,
  walletConnect,
} from "@thirdweb-dev/react";
import { Provider } from "react-redux";
import { store } from "./state";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Map } from "./pages/gardenMap";
import { PurpleForest } from "./pages/purpleForest";
import { Salon } from "./pages/salon";
import { FruitMarket } from "./pages/fruitMarket";
import { Arcade } from "./pages/arcade";
import { Castle } from "./pages/castle";
import { PartnerSubmission } from "./pages/partner";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5";
import {
  NETWORK_ID,
  TW_FACTORY_ADDRESS,
  WEB3_MODAL_CONFIG,
  WEB3_MODAL_METADATA,
  WEB3_MODAL_PROJECT_ID,
} from "./config/config";

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata: WEB3_MODAL_METADATA }),
  chains: [WEB3_MODAL_CONFIG[NETWORK_ID]],
  projectId: WEB3_MODAL_PROJECT_ID,
});

const config = {
  factoryAddress: TW_FACTORY_ADDRESS,
  gasless: true,
};

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <ThirdwebProvider
      clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet(),
        walletConnect(),
        embeddedWallet({
          auth: {
            options: ["email", "google"],
          },
        }),
      ]}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Map isMobile={isMobile} />} />
            <Route
              path="/purple-forest"
              element={<PurpleForest isMobile={isMobile} />}
            />
            <Route path="/salon" element={<Salon isMobile={isMobile} />} />
            <Route
              path="/fruit-market"
              element={<FruitMarket isMobile={isMobile} />}
            />
            <Route path="/arcade" element={<Arcade isMobile={isMobile} />} />
            <Route path="/castle" element={<Castle isMobile={isMobile} />} />
            <Route path="/partner" element={<PartnerSubmission isMobile={isMobile} />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </ThirdwebProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
