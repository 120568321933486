import styled from "styled-components";
import { ISeedOrTreeInfo } from "../../../state/nfts";
import { EmptyCard } from "./EmptyCard";
import { SeedCard } from "./SeedCards";
import { TreeCard } from "./TreeCards";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-row-gap: 75px;

  grid-template-areas:
    "card1 . card2 . card3"
    ". card4 . card5 ."
    ". . card6 . .";

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
`;
const Card1 = styled.div`
  grid-area: card1;
`;
const Card2 = styled.div`
  grid-area: card2;
`;
const Card3 = styled.div`
  grid-area: card3;
`;
const Card4 = styled.div`
  grid-area: card4;
`;
const Card5 = styled.div`
  grid-area: card5;
`;
const Card6 = styled.div`
  grid-area: card6;
`;

const NftView = ({
  nfts,
  index,
}: {
  nfts: ISeedOrTreeInfo[];
  index: number;
}) => {
  if (!nfts[index]) {
    return <EmptyCard />;
  } else if (nfts[index].type === "seed") {
    return <SeedCard seed={nfts[index]} />;
  } else {
    return <TreeCard tree={nfts[index]} />;
  }
};

export const SixBoxes = ({
  activeSeeds,
  activeTrees,
}: {
  activeSeeds: ISeedOrTreeInfo[];
  activeTrees: ISeedOrTreeInfo[];
}) => {
  const allNfts = activeSeeds.concat(activeTrees);
  const lengthOfAllNfts = allNfts.length;

  return (
    <Container>
      <Card1>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 1} />
      </Card1>
      <Card2>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 2} />
      </Card2>
      <Card3>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 3} />
      </Card3>
      <Card4>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 4} />
      </Card4>
      <Card5>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 5} />
      </Card5>
      <Card6>
        <NftView nfts={allNfts} index={lengthOfAllNfts - 6} />
      </Card6>
    </Container>
  );
};
