import {
  Card,
  CardBottom,
  CardContainer,
  CardImage,
  CardImageBox,
  NFTName,
} from "./styles";
import EmptyImage from "../../../images/purpleForest/nfts/emptyNft.svg";
import { SEED_GARDEN_CONTRACT_ADDRESS } from "../../../config/purple-config";
import { useAppDispatch, useAppSelector } from "../../../state";
import { setSeedsAndTreesAsync } from "../../../state/nfts";
import { setDashboardValuesAsync } from "../../../state/dashboard";
import { useAddress, useContract, useContractWrite } from "@thirdweb-dev/react";
import seedGardenAbi from "../../../config/abi/seedGarden.json";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";

export const EmptyCard = () => {
  const account = useAddress();
  const seedBalance = useAppSelector(
    (state) => state.dashboard.data.userData.balanceOfSeeds,
  );
  const { contract: seedGardenContract } = useContract(
    SEED_GARDEN_CONTRACT_ADDRESS,
    seedGardenAbi,
  );
  const { mutateAsync: mutateAsyncPlantSeed, isLoading: isPlanting } =
    useContractWrite(seedGardenContract, "plantSeed");
  const dispatch = useAppDispatch();

  return (
    <CardContainer>
      <Card>
        <CardImageBox>
          <CardImage src={EmptyImage} alt="No NFT" />
        </CardImageBox>
      </Card>
      <CardBottom
        $isloading={isPlanting}
        onClick={async () => {
          try {
            if (!new BigNumber(seedBalance).isGreaterThan(0)) {
              toast("No seeds to plant", {
                type: "error",
              });
              return;
            }

            await mutateAsyncPlantSeed({ args: [1] });
            toast("Success", {
              type: "success",
            });
            if (account && !isPlanting) {
              dispatch(setSeedsAndTreesAsync(account));
              dispatch(setDashboardValuesAsync(account));
            }
          } catch (err) {
            console.log("Error while planting a seed: ", err);
            toast("Error", {
              type: "error",
            });
          }
        }}
      >
        <NFTName>{isPlanting ? "Loading..." : "Plant"}</NFTName>
      </CardBottom>
    </CardContainer>
  );
};
