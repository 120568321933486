import styled from "styled-components";
import { Link } from "react-router-dom";
import BackButtonImage from "../../../images/purpleForest/navbar/backButton.png";
import NavSubLongLeftImage from "../../../images/purpleForest/navbar/navSubLongHeadingLeft.svg";
import NavSubLongRightImage from "../../../images/purpleForest/navbar/navSubLongHeadingRight.svg";
import NavSubLeftImage from "../../../images/purpleForest/navbar/navSubHeadingLeft.svg";
import NavSubRightImage from "../../../images/purpleForest/navbar/navSubHeadingRight.svg";
import NavHeadingImage from "../../../images/purpleForest/navbar/navHeadingWithText.png";
import { ConnectWallet } from "@thirdweb-dev/react";

const NavMainContainer = styled.div``;
const NavigationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
`;
const BackButtonContainer = styled.div`
  max-width: 200px;
  width: 100%;

  @media (max-width: 1023px) {
    display: none;
  }
`;
const BackButton = styled.img``;
const NavHeadingContainer = styled.div`
  display: flex;
`;
const NavSubHeadingLongLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongLeft = styled.img``;
const NavSubHeadingLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLeft = styled.img``;
const NavHeadingBox = styled.div`
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;
const NavHeading = styled.img`
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;
const NavSubHeadingRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingRight = styled.img``;
const NavSubHeadingLongRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongRight = styled.img``;
const ConnectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  max-width: 200px;
  width: 100%;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`;

const Menu = () => {
  // const userBalance = useAppSelector(
  //     (state) => state.dashboard.data.userData.balanceOfMatic
  // );

  return (
    <NavMainContainer>
      <NavigationContainer>
        <BackButtonContainer>
          <Link to="/">
            <BackButton src={BackButtonImage} />
          </Link>
        </BackButtonContainer>

        <NavHeadingContainer>
          <NavSubHeadingLongLeftBox>
            <NavSubHeadingLongLeft src={NavSubLongLeftImage} />
          </NavSubHeadingLongLeftBox>

          <NavSubHeadingLeftBox>
            <NavSubHeadingLeft src={NavSubLeftImage} />
          </NavSubHeadingLeftBox>

          <NavHeadingBox>
            <NavHeading src={NavHeadingImage} />
          </NavHeadingBox>

          <NavSubHeadingRightBox>
            <NavSubHeadingRight src={NavSubRightImage} />
          </NavSubHeadingRightBox>

          <NavSubHeadingLongRightBox>
            <NavSubHeadingLongRight src={NavSubLongRightImage} />
          </NavSubHeadingLongRightBox>
        </NavHeadingContainer>

        <ConnectButtonContainer>
          <ConnectWallet
            theme={"light"}
            switchToActiveChain={true}
            modalSize={"compact"}
            hideSwitchToPersonalWallet={true}
          />
        </ConnectButtonContainer>
      </NavigationContainer>
    </NavMainContainer>
  );
};

export default Menu;
