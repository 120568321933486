import { useState } from "react";
import {
  CardContainer,
  Card,
  CardImageBox,
  CardTop,
  CardBottom,
  CardImage,
  NFTName,
  StyledModalBox,
  ClaimButton,
  CongratsHeading,
  ClaimButtonText,
  CongratsSubContainer,
  CongratsSubText,
  StyledFruitImage,
  TwoButtonBox,
} from "./styles";
import TreeImage from "../../../images/purpleForest/nfts/tree.svg";
import FruitImage from "../../../images/purpleForest/nfts/fruit.svg";
import AncientTreeImage from "../../../images/purpleForest/nfts/ancient-tree.svg";
import Countdown from "react-countdown";
import Modal from "react-modal";
import {
  MAX_TREE_LIFETIME_WATER_COUNT,
  MIN_WATER_DURATION,
  TREE_COMPLETION_COUNT,
  TREE_GARDEN_CONTRACT_ADDRESS,
} from "../../../config/purple-config";
import { ISeedOrTreeInfo, setSeedsAndTreesAsync } from "../../../state/nfts";
import { useAppDispatch } from "../../../state";
import { Tooltip } from "react-tooltip";
import { useAddress, useContract, useContractWrite } from "@thirdweb-dev/react";
import { setDashboardValuesAsync } from "../../../state/dashboard";
import treeGardenAbi from "../../../config/abi/treeGarden.json";
import { TreeGarden__factory } from "../../../config/abi/types";
import { JSON_RPC_PROVIDER } from "../../../config/config";
import { toast } from "react-toastify";

export const TreeCard = ({ tree }: { tree: ISeedOrTreeInfo }) => {
  const account = useAddress();

  const { contract: treeGardenContract } = useContract(
    TREE_GARDEN_CONTRACT_ADDRESS,
    treeGardenAbi,
  );

  const { mutateAsync: mutateAsyncClaimTree, isLoading: isClaiming } =
    useContractWrite(treeGardenContract, "claimTree");
  const { mutateAsync: mutateAsyncWaterPlants, isLoading: isWatering } =
    useContractWrite(treeGardenContract, "waterPlants");

  const [open, setOpen] = useState(false);
  const [showLotterySuccessModal, setShowLotterySuccessModal] = useState(false);

  const dispatch = useAppDispatch();

  let status: string;
  if (tree.status === "0") status = "NotFound";
  else if (tree.status === "1") status = "Planted";
  else if (tree.status === "2") status = "Expired";
  else if (tree.status === "3") status = "Claimable";
  else status = "Claimed";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClaimRewardClick = async () => {
    try {
      await mutateAsyncClaimTree({ args: [tree.id] });
      toast("Success", {
        type: "success",
      });
      if (account && !isClaiming) {
        setOpen(false);
        setShowLotterySuccessModal(false);
        dispatch(setSeedsAndTreesAsync(account));
        dispatch(setDashboardValuesAsync(account));
      }
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleClaimRewardClick: ", e);
    }
  };

  const handleWaterClick = async () => {
    try {
      await mutateAsyncWaterPlants({ args: [tree.id] });
      toast("Success", {
        type: "success",
      });
      if (account && !isWatering) {
        const treeGardenContract = TreeGarden__factory.connect(
          TREE_GARDEN_CONTRACT_ADDRESS,
          JSON_RPC_PROVIDER,
        );
        const status = await treeGardenContract.getPlantStatus(tree.id);
        if (status.toString() === "3") {
          setShowLotterySuccessModal(() => true);
        }
        dispatch(setSeedsAndTreesAsync(account));
      }
    } catch (e) {
      toast("Error", {
        type: "error",
      });
      console.log("Error in handleWaterClick: ", e);
    }
  };

  if (status === "Claimed") {
    return (
      <CardContainer>
        <Card>
          <CardImageBox>
            <CardImage src={TreeImage} alt="Tree NFT" />
          </CardImageBox>
        </Card>
        <CardBottom>
          <NFTName>Claimed</NFTName>
        </CardBottom>
      </CardContainer>
    );
  }

  if (tree.lifetimeWateredCount === MAX_TREE_LIFETIME_WATER_COUNT.toString()) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim Ancient Tree"
          aria-describedby="Claim the ancient tree for watering the tree till it's end of life"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={AncientTreeImage} alt="Ancient Tree" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive an Ancient Tree
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Card>
          <CardImageBox>
            <CardImage src={AncientTreeImage} alt="Ancient Tree NFT" />
          </CardImageBox>
        </Card>
        <CardBottom $isloading={isClaiming} onClick={handleOpen}>
          <NFTName>{isClaiming ? "Loading..." : "Claim"}</NFTName>
        </CardBottom>
      </CardContainer>
    );
  }

  if (
    status === "Claimable" ||
    tree.wateredCount >= TREE_COMPLETION_COUNT.toString()
  ) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the tree"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the tree rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the fruits right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Card>
          <CardImageBox>
            <CardImage src={TreeImage} alt="Tree NFT" />
          </CardImageBox>
        </Card>
        <CardBottom $isloading={isClaiming} onClick={handleOpen}>
          <NFTName>{isClaiming ? "Loading..." : "Claim"}</NFTName>
        </CardBottom>
      </CardContainer>
    );
  }

  if (Date.now() < parseInt(tree.nextWaterTime) * 1000) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the tree"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the tree rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the fruits right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <CardTop style={{ background: "#679a7e" }}>
          <NFTName>Next water</NFTName>
          <NFTName>
            <>
              <Countdown
                key={tree.lastWatered}
                date={parseInt(tree.nextWaterTime) * 1000}
                onComplete={() => dispatch(setSeedsAndTreesAsync(account!))}
              >
                <p>Water now</p>
              </Countdown>
            </>
          </NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={TreeImage} alt="Tree NFT" />
          </CardImageBox>
        </Card>
      </CardContainer>
    );
  } else if (
    Date.now() > parseInt(tree.nextWaterTime) * 1000 &&
    Date.now() < (parseInt(tree.nextWaterTime) + MIN_WATER_DURATION) * 1000
  ) {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the tree"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the tree rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the fruits right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <CardTop style={{ backgroundColor: "#cc4747" }}>
          <NFTName>Expires in</NFTName>
          <NFTName>
            <>
              <Countdown
                key={tree.nextWaterTime}
                date={
                  (parseInt(tree.nextWaterTime) + MIN_WATER_DURATION) * 1000
                }
                onComplete={() => dispatch(setSeedsAndTreesAsync(account!))}
              >
                <div>Expired</div>
              </Countdown>
            </>
          </NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={TreeImage} alt="Tree NFT" />
          </CardImageBox>
        </Card>
        <CardBottom $isloading={isWatering} onClick={handleWaterClick}>
          <NFTName>{isWatering ? "Loading..." : "Water"}</NFTName>
        </CardBottom>
      </CardContainer>
    );
  } else {
    return (
      <CardContainer>
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          aria-labelledby="Claim reward"
          aria-describedby="Claim the reward for watering the tree"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations</CongratsHeading>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit reward" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton onClick={handleClose}>
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <Modal
          isOpen={showLotterySuccessModal}
          onRequestClose={() => setShowLotterySuccessModal(false)}
          aria-labelledby="Lottery outcome"
          aria-describedby="Is the user lucky to directly claim the tree rewards"
        >
          <StyledModalBox>
            <CongratsHeading>Congratulations, You got lucky!!!</CongratsHeading>
            <CongratsSubText>
              You can claim the fruits right now!
            </CongratsSubText>
            <CongratsSubContainer>
              <div>
                <StyledFruitImage src={FruitImage} alt="Fruit" />
              </div>
              <div>
                <CongratsSubText>
                  You will receive {tree.amount}x fruits
                </CongratsSubText>
                <TwoButtonBox>
                  <ClaimButton
                    onClick={() => setShowLotterySuccessModal(false)}
                  >
                    <ClaimButtonText>Back</ClaimButtonText>
                  </ClaimButton>
                  <ClaimButton
                    isLoading={isClaiming}
                    onClick={handleClaimRewardClick}
                  >
                    <ClaimButtonText>
                      {isClaiming ? "Loading..." : "Claim"}
                    </ClaimButtonText>
                  </ClaimButton>
                </TwoButtonBox>
              </div>
            </CongratsSubContainer>
          </StyledModalBox>
        </Modal>

        <CardTop style={{ backgroundColor: "#cc4747" }}>
          <NFTName>Expired</NFTName>
        </CardTop>
        <Card>
          <CardImageBox>
            <CardImage src={TreeImage} alt="Tree NFT" />
          </CardImageBox>
        </Card>
        <CardBottom
          $isloading={isWatering}
          onClick={handleWaterClick}
          data-tooltip-id="replant-expired-nft"
          data-tooltip-content={`You can still water because you have ${tree.skipsAllowed} skips allowed`}
          data-tooltip-place="bottom"
        >
          <NFTName>{isWatering ? "Loading..." : "Water"}</NFTName>
          <Tooltip id="replant-expired-nft" />
        </CardBottom>
      </CardContainer>
    );
  }
};
