import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { fetchDashboardValues } from "./fetchDashboardValues";

const initialState = {
  data: {
    totalSupplyOfSeeds: "0",
    totalSupplyOfFrens: "0",
    totalSupplyOfFruits: "0",
    totalSupplyOfTrees: "0",
    totalSupplyOfAncientTrees: "0",
    userData: {
      balanceOfMatic: "0",
      isSeedApproved: false,
      isTreeApproved: false,
      balanceOfSeeds: "0",
      balanceOfFrens: "0",
      balanceOfFruits: "0",
      balanceOfTrees: "0",
      balanceOfAncientTrees: "0",
    },
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardDataAction: (
      state,
      action: PayloadAction<{
        isSeedApproved: boolean;
        isTreeApproved: boolean;

        totalSupplyOfSeeds: string;
        totalSupplyOfFrens: string;
        totalSupplyOfFruits: string;
        totalSupplyOfTrees: string;
        totalSupplyOfAncientTrees: string;

        balanceOfSeeds: string;
        balanceOfFrens: string;
        balanceOfFruits: string;
        balanceOfTrees: string;
        balanceOfAncientTrees: string;
      }>,
    ) => {
      state.data.userData.isSeedApproved = action.payload.isSeedApproved;
      state.data.userData.isTreeApproved = action.payload.isTreeApproved;

      state.data.totalSupplyOfSeeds = action.payload.totalSupplyOfSeeds;
      state.data.totalSupplyOfFrens = action.payload.totalSupplyOfFrens;
      state.data.totalSupplyOfFruits = action.payload.totalSupplyOfFruits;
      state.data.totalSupplyOfTrees = action.payload.totalSupplyOfTrees;
      state.data.totalSupplyOfAncientTrees =
        action.payload.totalSupplyOfAncientTrees;

      state.data.userData.balanceOfSeeds = action.payload.balanceOfSeeds;
      state.data.userData.balanceOfFrens = action.payload.balanceOfFrens;
      state.data.userData.balanceOfFruits = action.payload.balanceOfFruits;
      state.data.userData.balanceOfTrees = action.payload.balanceOfTrees;
      state.data.userData.balanceOfAncientTrees =
        action.payload.balanceOfAncientTrees;
    },
  },
});

export const { setDashboardDataAction } = dashboardSlice.actions;

export const setDashboardValuesAsync =
  (account: string) => async (dispatch: Dispatch) => {
    const info = await fetchDashboardValues(account);
    if (info) {
      dispatch(setDashboardDataAction(info));
    }
  };

export default dashboardSlice.reducer;
