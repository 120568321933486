import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import { useNftData } from "./useNftData";

import DialogC1 from "../../images/map/dialogC1.svg";
import DialogC2 from "../../images/map/dialogC2.svg";
import Previous from "../../images/map/previous.png";
import Next from "../../images/map/next.png";
import Close from "../../images/map/close.png";

import t1 from "../../images/map/arkmap/am-6.png";
import t2 from "../../images/map/arkmap/am-8.png";
import t3 from "../../images/map/arkmap/am-3.png";
import t4 from "../../images/map/arkmap/am-9.png";
import t5 from "../../images/map/arkmap/am-4.png";
import t6 from "../../images/map/arkmap/am-1.png";
import t7 from "../../images/map/arkmap/am-5.png";





const DialogViews = () => {
  const dialogView_1 = useRef([]);
  const dialogView_2 = useRef([]);

  const { address } = useNftData();


  useEffect(() => {
    // Create staggered animation for both boxes
    // gsap.fromTo(
    //   boxes_1,
    //   { opacity: 0, y: 150 }, // Starting state
    //   { opacity: 1, y: 0, ease: 'power2.out', delay: 1.5, duration: 1.2, stagger: 1 } // Ending state and stagger delay
    // );
  }, []);

  const [dialogView, setdialogView] = useState(0);

  const handleDialog = () => {
    setdialogView(dialogView + 1)
  }
  const handleDialogPrev = () => {
    setdialogView(dialogView - 1)
  }

  const [animeOne, setanimeOne] = useState(false);
  const [animView, setanimView] = useState(0);

  const [popupView, setpopupView] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem('visitedBefore') && address) {
      localStorage.setItem('visitedBefore', 'true');
      setanimView(1)
    } else {
      setanimView(0)
    }
  }, []);

  useEffect(() => {
    testAnim(animView)

  }, [animView]);

  const testAnim = (animView) => {
    const boxes_1 = dialogView_1.current;
    const boxes_2 = dialogView_2.current;

    switch (animView) {
      case 1:
        gsap.fromTo(
          boxes_1,
          { opacity: 0, y: 80 }, // Starting state
          { opacity: 1, y: 0, ease: 'power2.out', delay: 0.5, duration: 1.2, stagger: 0.7 } // Ending state and stagger delay
        );
        break;
      case 2:
        gsap.fromTo(
          boxes_2,
          { opacity: 0, y: 80 }, // Starting state
          { opacity: 1, y: 0, ease: 'power2.out', delay: 0.5, duration: 1.2, stagger: 0.7 } // Ending state and stagger delay
        );
        break;
      default:
      // code block
    }
    // const boxes_1 = dialogView_1.current;
    // let anim = null;
    // if (!animeOne) {
    //   anim = gsap.fromTo(
    //     boxes_1,
    //     { opacity: 0, y: 150 }, // Starting state
    //     { opacity: 1, y: 0, ease: 'power2.out', delay: 1.5, duration: 1.2, stagger: 1 } // Ending state and stagger delay
    //   );
    //   setanimeOne(true)
    // }

    // if (animation) {
    //   anim.reverse()
    //   setanimeOne(false)
    // }
  }

  const closeAnimation = () => {
    setpopupView(false)
    setanimView(0)
  }

  const startAnimation = () => {
    setanimView(1)
    setpopupView(true)
  }

  return (
    <div
      className={`dialog-view ${animView > 0 && animView < 3 ? "d-view" : ""}`}
    >
      {/* <h2 style={{ color: "#000" }}>ANim No : {animView}</h2> */}

      <div className="dialog-view__container">

        {
          (animView === 10 || animView === 0) && (
            <button className="btn-intro" onClick={() => startAnimation()}>Learn More</button>
          )
        }
        {
          (animView !== 10 && animView !== 0 && popupView) && (
            <div className="dialog-view__buttons">
              <button className="btn-round" disabled={animView === 1} onClick={() => setanimView(animView - 1)} style={{ position: 'relative', zIndex: 2 }}> <img src={Previous} alt="" /> </button>
              <button className="btn-round" disabled={animView === 10} onClick={() => setanimView(animView + 1)} style={{ position: 'relative', zIndex: 2 }}> <img src={Next} alt="" /></button>
              <button className="btn-round" disabled={animView > 10} onClick={() => closeAnimation()} style={{ position: 'relative', zIndex: 2 }}> <img src={Close} alt="" /></button>
            </div>
          )
        }

        {animView === 1 && (
          <div className="dialog-view__dialog dialog-view__dialog--1">
            <div
              className="dialog-view__dialog-box"
              ref={(el) => (dialogView_1.current[1] = el)}
            >
              <div className="dialog-view__character-name dialog-view__character-name--c1">
                <span>Purple Fren</span>
              </div>
              <div className="dialog-view__dialog-txt dialog-view__dialog-txt--1">
                <p>*GRUMP* *GRUMP*</p>
              </div>
            </div>
            <div className="dialog-view__dialog-characters">
              <img
                src={DialogC1}
                ref={(el) => (dialogView_1.current[0] = el)}
                alt=""
              />
            </div>
          </div>
        )}
        {animView === 2 && (
          <div className="dialog-view__dialog dialog-view__dialog--2">
            <div
              className="dialog-view__dialog-box"
              ref={(el) => (dialogView_2.current[3] = el)}
            >
              <div className="dialog-view__character-name dialog-view__character-name--c2">
                <span>Arkadian</span>
              </div>
              <div className="dialog-view__dialog-txt dialog-view__dialog-txt--1">
                <p>Hello there! Hope you enjoy the mysteries of Arkadia Park!</p>
              </div>
            </div>
            <div className="dialog-view__dialog-characters">
              <img
                src={DialogC1}
                ref={(el) => (dialogView_2.current[0] = el)}
                alt=""
              />
              <img
                src={DialogC2}
                ref={(el) => (dialogView_2.current[1] = el)}
                alt=""
              />
            </div>
          </div>
        )}

        {
          animView == 3 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t1} alt="" />
                <h2>The Salon</h2>
                <p>First, we  have the Salon! This is a design hub where you can create other Purple Frens. Experiment with different styles and swap your Purple Hair for Purple Seeds. Let your creativity flow and expand your collection of unique Purple Frens. </p>
              </div>
            </div>
          )
        }
        {
          animView == 4 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t2} alt="" />
                <h2>Purple Forest</h2>
                <p>
                  Next, the Purple Forest! This lush and vibrant area is where you can plant your Purple Seeds, water your Purple Trees, and harvest Purple Fruits. Engage in the Harvest-2-Earn cycle and watch your Purple Forest flourish. This is also one of the places where you can make use of your Purple Frens. The more you nurture your plants, the greater your rewards!</p>
              </div>
            </div>
          )
        }

        {
          animView == 5 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t3} alt="" />
                <h2>Auction House</h2>
                <p>
                  Here is the Auction House! Here you can Bid-to-Earn! Participate in exciting auctions, place your bids, and win exclusive items! The Auction House is the perfect place to acquire rare and valuable assets in Arkadia Park!</p>
              </div>
            </div>
          )
        }

        {
          animView == 6 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t4} alt="" />
                <h2>Fruit Market</h2>
                <p>
                  Step into the Fruit Market! This bustling market is where you can swap your harvested fruits for a variety of items. Explore the market, find the best deals, and trade your fruits for useful and unique items that enhance your Arkadia Park experience.</p>
              </div>
            </div>
          )
        }


        {
          animView == 7 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t5} alt="" />
                <h2>DAO ARK</h2>
                <p>
                  Welcome to the DAO ARK! This is the heart of our community where important decisions are made by the Arkadians. Here, you can participate in governance and have a say in the future of Arkadia Park. Your voice matters, so be sure to engage and contribute to our thriving ecosystem.</p>
              </div>
            </div>
          )
        }


        {
          animView == 8 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`}>
              <div>
                <img src={t6} alt="" />
                <h2>Arcade</h2>
                <p>
                  Welcome to the Arcade! Here, you can Play to Earn. Dive into a variety of fun and challenging games. Show off your skills, earn rewards, and enjoy endless entertainment in the Arcade. </p>
              </div>
            </div>
          )
        }

        {
          animView == 9 && (
            <div className={`menu-items ${popupView ? 'd-flex' : ''}`} >
              <div>
                <img src={t7} alt="" />
                <h2>The Castle</h2>
                <p>
                  Finally, we arrive at the Castle! This mysterious and intriguing location is where the secrets of Arkadia Park are being uncovered. Keep an eye on the Castle, as it holds many surprises and the ongoing mystery that keeps the park exciting.</p>
              </div>
            </div>
          )
        }



      </div>
    </div>
  );
};

export default DialogViews;
