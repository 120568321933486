import Menu from "./components/Menu";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import { Home, MainButton, MainButtonText } from "./components/Home";
import styled from "styled-components";
import { TransferAssets } from "../../components/TransferAssets";
import { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import PlayAudio from "../../components/PlayAudio";
import FruitMarketMusic from "../../music/fruitMarket.wav";

const ConnectPage = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const ConnectHeading = styled.h1`
  margin: 50px;
  text-align: center;
  font-size: 44px;
  color: white;
`;

const Banner = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
  background-color: #6d83ed;
  padding: 8px 15px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
`;

const ConnectWalletContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  max-width: 1400px;
  margin: 10px auto;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`;
const MintModalInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;

  border-radius: 15px;
  border: 1px solid #000;
  background: #6d83ed;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;

  @media (max-width: 1023px) {
    border-radius: 8px;
  }
`;
const MintModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;
const BalanceHeading = styled.div`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`;
const MobileMenuLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  max-width: 1400px;
  margin: 10px auto;
  flex-wrap: wrap;
`;
const MobileLink = styled(Link)`
  font-size: 24px;
  color: white;
  text-decoration: none;
  margin: 10px;
  background: purple;
  border-radius: 12px;
  padding: 5px 15px;

  &:hover {
    text-decoration: underline;
  }
`;

export function FruitMarket({ isMobile }: { isMobile: boolean }) {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const address = useAddress();

  return (
    <main className="main">
      <div className="FruitBgImgContainer">
        <Menu />
        {isMobile && (
          <MobileMenuLinks>
            <MobileLink to="/">Garden</MobileLink>
            <MobileLink to="/purple-forest">Purple Forest</MobileLink>
          </MobileMenuLinks>
        )}

        <ConnectWalletContainer>
          <MainButton
            onClick={() => setIsTransferModalOpen(true)}
            disabled={address === undefined}
          >
            <MainButtonText>Transfer Assets</MainButtonText>
          </MainButton>
          <ConnectWallet
            theme={"light"}
            switchToActiveChain={true}
            modalSize={"compact"}
            hideSwitchToPersonalWallet={true}
            style={{ background: "#C5D9DA", color: "black" }}
          />
        </ConnectWalletContainer>
        {isMobile && <Banner>You can add this App to the Home screen</Banner>}
        {address ? (
          <Home account={address} />
        ) : (
          <ConnectPage>
            <ConnectHeading>Please connect your account</ConnectHeading>
          </ConnectPage>
        )}
        <Modal
          isOpen={isTransferModalOpen}
          onRequestClose={() => setIsTransferModalOpen(false)}
          aria-labelledby="Transfer Assets"
          aria-describedby="Modal to transfer assets between accounts"
        >
          <MintModalInnerContainer>
            <MintModalContent>
              <BalanceHeading>Transfer Assets</BalanceHeading>
              {address && <TransferAssets smartWalletAddress={address} />}
              <MainButton onClick={() => setIsTransferModalOpen(false)}>
                <MainButtonText>Close</MainButtonText>
              </MainButton>
            </MintModalContent>
          </MintModalInnerContainer>
        </Modal>
      </div>
      <PlayAudio music={FruitMarketMusic} />
    </main>
  );
}
